import { useState } from 'react';
import axios from 'axios';

export const useGetIPs = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ips, setIPs] = useState<any>([]);

    const getIPs = async (page: number = 1, limit: number = 10) => {
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/ip`, {
                params: {
                    page,
                    limit
                },
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            setIsLoading(false);
            setIPs(response.data);
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong');
            }
            setIsLoading(false);
        }
    }

    return { ips, getIPs, error, isLoading };
}