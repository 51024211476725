import React from 'react';
import './App.css';
import { Home } from './pages/home/Home';
import { Footer } from './components/footer/Footer';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './pages/login/Login';
import { Register } from './pages/register/Register';
import { useAuthContext } from './hooks/useAuthContext';
import { Admin } from './pages/admin/Admin';
import { Transaction } from './pages/transaction/Transaction';
import { Loader } from './components/loader/Loader';
import TransactionList from './pages/admin/transaction-list/TransactionList';
import UpdateTransaction from './pages/admin/update-transaction/UpdateTransaction';
import Ips from './pages/admin/ips/Ips';

function App() {

  const { user, loading } = useAuthContext();

  if (loading)
    return <Loader />

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin" element={user ? <Admin /> : <Navigate to="/" />} />
          <Route path="/admin/ips" element={user ? <Ips /> : <Navigate to="/" />} />
          <Route path="/admin/transactions" element={user ? <TransactionList /> : <Navigate to="/" />} />
          <Route path="/admin/:id" element={user ? <UpdateTransaction /> : <Navigate to="/" />} />
          <Route path="/:chain/transaction/:transactionId" element={<Transaction />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
