import { useState } from 'react';
import axios from 'axios';

export const useSearchTransaction = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transactionData, setTransactionData] = useState<any>(null);

    const searchTransaction = async (hash: string) => {
        setIsLoading(true);
        setError('');
        setTransactionData(null);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/blockchair/search?hash=${hash}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setTransactionData(response.data);
            setIsLoading(false);
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong while searching for the transaction');
            }
            setIsLoading(false);
        }
    };

    return { searchTransaction, transactionData, error, isLoading };
};