import { useState, useEffect } from 'react';
import axios from 'axios';
import { TransactionData } from '../interfaces/TransactionData';

export const useGetTransactionById = (transactionId: string): {transactionData: TransactionData | null, isLoading: boolean, error: string} => {
    const [transactionData, setTransactionData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTransaction = async () => {
            setIsLoading(true);
            setError('');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/transaction/${transactionId}`);
                setTransactionData(response.data);
            } catch (err: any) {
                if (err.response && err.response.data) {
                    setError(err.response.data.message || 'Something went wrong while updating the transaction');
                } else {
                    setError('An error occurred while updating the transaction');
                }
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        if (transactionId) {
            fetchTransaction();
        }
    }, [transactionId]);

    return { transactionData, isLoading, error };
};