import React from 'react';

import './Services.css';

export const Services: React.FC = () => {
    return (
        <div className="hp-products-wrap">
            <div className="hp-products-content">
                <section>
                    <h2 className="h2"> Services </h2>
                    <div className="hp-products__grid">
                        <a className="hp-banner-wrap" href="/markets" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/portfolio.svg" className="hp-banner__icon" alt="portfolio logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Anonymous portfolio tracker</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">
                                            Track the performance of your crypto assets portfolio — completely anonymously
                                        </p>
                                    <div className="hp-banner__body__cta as-start"> 
                                        Set my portfolio 
                                        <span className="hp-banner__body__cta__arrow-icon ml-5 rtl-flip">
                                            <svg width="14" height="15" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 7.9C0.892487 7.9 0.4 8.39249 0.4 9C0.4 9.60751 0.892487 10.1 1.5 10.1V7.9ZM16.2778 9.77782C16.7074 9.34824 16.7074 8.65176 16.2778 8.22218L9.27746 1.22183C8.84788 0.792249 8.1514 0.792249 7.72183 1.22183C7.29225 1.6514 7.29225 2.34788 7.72183 2.77746L13.9444 9L7.72183 15.2225C7.29225 15.6521 7.29225 16.3486 7.72183 16.7782C8.1514 17.2078 8.84788 17.2078 9.27746 16.7782L16.2778 9.77782ZM1.5 10.1H15.5V7.9H1.5V10.1Z" fill="#2170FF"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/pdf" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/pdf.svg" className="hp-banner__icon" alt="pdf logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Transaction receipts</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Get a PDF receipt for either you or your counterpart`s purposes</p>
                                        <div className="hp-banner__body__cta as-start"> Get PDF receipt 
                                        <span className="hp-banner__body__cta__arrow-icon ml-5 rtl-flip">
                                            <svg width="14" height="15" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 7.9C0.892487 7.9 0.4 8.39249 0.4 9C0.4 9.60751 0.892487 10.1 1.5 10.1V7.9ZM16.2778 9.77782C16.7074 9.34824 16.7074 8.65176 16.2778 8.22218L9.27746 1.22183C8.84788 0.792249 8.1514 0.792249 7.72183 1.22183C7.29225 1.6514 7.29225 2.34788 7.72183 2.77746L13.9444 9L7.72183 15.2225C7.29225 15.6521 7.29225 16.3486 7.72183 16.7782C8.1514 17.2078 8.84788 17.2078 9.27746 16.7782L16.2778 9.77782ZM1.5 10.1H15.5V7.9H1.5V10.1Z" fill="#2170FF"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/nodes" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/node-explorer.svg" className="hp-banner__icon" alt="node-explorer logo" loading="lazy" width="150" height="150" /></div>
                                    <div className="hp-banner__body d-flex fd-col">
                                        <h3 className="h3">Node explorer</h3>
                                        <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                            <p className="p js-end">
                                                Learn about node accessibility, locations, consensus and more
                                            </p>
                                            <div className="hp-banner__body__cta as-start"> Node explorer 
                                                <span className="hp-banner__body__cta__arrow-icon ml-5 rtl-flip">
                                                    <svg width="14" height="15" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 7.9C0.892487 7.9 0.4 8.39249 0.4 9C0.4 9.60751 0.892487 10.1 1.5 10.1V7.9ZM16.2778 9.77782C16.7074 9.34824 16.7074 8.65176 16.2778 8.22218L9.27746 1.22183C8.84788 0.792249 8.1514 0.792249 7.72183 1.22183C7.29225 1.6514 7.29225 2.34788 7.72183 2.77746L13.9444 9L7.72183 15.2225C7.29225 15.6521 7.29225 16.3486 7.72183 16.7782C8.1514 17.2078 8.84788 17.2078 9.27746 16.7782L16.2778 9.77782ZM1.5 10.1H15.5V7.9H1.5V10.1Z" fill="#2170FF"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/awesome" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/awesome.svg" className="hp-banner__icon" alt="awesome logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Blockchair Awesome</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">
                                            Find and compare awesome blockchain and crypto products and services
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/news" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/news.svg" className="hp-banner__icon" alt="news logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">News Aggregator</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Catch up with the latest news from 60 biggest crypto outlets. Available in 11 languages</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/donut" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/donut.svg" className="hp-banner__icon" alt="donut logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Blockchair Donut</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Donate to amazing nonprofits and open-source projects. Help crypto adoption and reduce tax payments</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/compare" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/compare.svg" className="hp-banner__icon" alt="compare logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Compare blockchains</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Compare crypto by size, fees, transactions per second, and more</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/tools/release-monitor" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/release-monitor.svg" className="hp-banner__icon" alt="release-monitor logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Release monitor</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Track upcoming hard forks and latest updates to cryptocurrency clients, like Bitcoin Core and Geth</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/broadcast" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/broadcast.svg" className="hp-banner__icon" alt="broadcast logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Broadcast transaction</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Broadcast raw transactions for BTC, ETH, BCH, LTC, BSV, DOGE and GRS privately</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/tools/halving-countdown" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/halvings.svg" className="hp-banner__icon" alt="halvings logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Halving countdown</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Miners, transaction fees and the block reward</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/extensions" target="_blank" rel="noreferrer">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/extension.svg" className="hp-banner__icon" alt="extension logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">Get Blockchair extension</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Blockchair brings the search engine for 17 blockchains to your browser</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a className="hp-banner-wrap" href="https://blockchair.com/whois" target="_self">
                            <div className="hp-banner-content">
                                <div className="hp-banner__icon-wrap">
                                    <img src="https://loutre.blockchair.io/assets/images/products/ens-icon.svg" className="hp-banner__icon" alt="ens-icon logo" loading="lazy" width="150" height="150" />
                                </div>
                                <div className="hp-banner__body d-flex fd-col">
                                    <h3 className="h3">ENS Lookup</h3>
                                    <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                        <p className="p js-end">Search Ethereum Name Service domain names</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    )
}