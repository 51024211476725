import React from 'react'

import './Admin.css';
import CreateTransaction from './create-transaction/CreateTransaction';
import AdminNavbar from './admin-navbar/AdminNavbar';

export const Admin: React.FC = (): JSX.Element => {
    return (
        <>
            <AdminNavbar />
            <section className="admin">
                <CreateTransaction />
            </section>
        </>
    )
}