import React, { useState } from 'react';
import { TransactionFormData } from '../../../interfaces/transaction';
import { useCreateTransaction } from '../../../hooks/useCreateTransaction';

import './CreateTransaction.css';

const CreateTransaction: React.FC = () => {
  const [transactionData, setTransactionData] = useState<TransactionFormData>({
    block_id: 0,
    hash: '',
    chain: '',
    input_total: 0,
    input_total_usd: 0,
    fee: 0,
    fee_usd: 0,
    fee_per_kb: 0,
    inputs: [{ value: 0, value_usd: 0, recipient: '' }],
    outputs: [{ value: 0, value_usd: 0, recipient: '' }],
    timeUntilFirstConfirmation: 0,
    timeBetweenConfirmations: 0,
  });
  const { createTransaction, isLoading, error, isSuccess } = useCreateTransaction();

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, type: 'inputs' | 'outputs') => {
    const newData = { ...transactionData };
    const value = event.target.type === 'number' ? Number(event.target.value) : event.target.value;

    // Updating Input or Output based on 'type' parameter
    if (type === 'inputs') {
        const updatedInputs = newData.inputs.map((input, i) => 
            i === index ? { ...input, [event.target.name]: value } : input
        );
        setTransactionData({ ...newData, inputs: updatedInputs });
    } else {
        const updatedOutputs = newData.outputs.map((output, i) => 
            i === index ? { ...output, [event.target.name]: value } : output
        );
        setTransactionData({ ...newData, outputs: updatedOutputs });
    }
  };

  const addInputOutput = (type: 'inputs' | 'outputs') => {
    const newData = { ...transactionData };
    newData[type].push({ value: 0, value_usd: 0, recipient: '' });
    setTransactionData(newData);
  };

  const removeInputOutput = (index: number, type: 'inputs' | 'outputs') => {
    const newData = { ...transactionData };
    newData[type].splice(index, 1);
    setTransactionData(newData);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    createTransaction(transactionData);
  };

  const handleGeneralChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setTransactionData(prevData => ({
      ...prevData,
      [name]: name === 'block_id' ? parseInt(value) : value
    }));
  };
  

  return (
    <div className="create-transaction">
      <h2>Create Transaction</h2>
      <form onSubmit={handleSubmit}>
        {/* Transaction details */}
        <div className="transaction-details">
            <div className="field">
                <label>Block ID:</label>
                <input type="number" name="block_id" value={transactionData.block_id} onChange={handleGeneralChange} />
            </div>

            <div className="field">
                <label>Hash:</label>
                <input type="text" name="hash" value={transactionData.hash} onChange={handleGeneralChange} />
            </div>

            <div className="field">
                <label>Cryptocurrency:</label>
                <select name="chain" value={transactionData.chain} onChange={handleGeneralChange}>
                <option value="">Select</option>
                <option value="bitcoin">Bitcoin (BTC)</option>
                <option value="ethereum">Ethereum (ETH)</option>
                
                </select>
            </div>
        </div>
  
        {/* Inputs */}
        <div className="transaction-fields">
            <div className="transaction-inputs">
            <h3>Inputs</h3>
            {transactionData.inputs.map((input, index) => (
                <div key={index} className="input">
                    <label>Value:</label>
                    <input type="number" name="value" value={input.value} onChange={e => handleInputChange(index, e, 'inputs')} />
                    <label>USD Value:</label>
                    <input type="number" name="value_usd" value={input.value_usd} onChange={e => handleInputChange(index, e, 'inputs')} />
                    
                    <label>Recipient:</label>
                    <input type="text" name="recipient" value={input.recipient} onChange={e => handleInputChange(index, e, 'inputs')} />
                    <button type="button" className="red" onClick={() => removeInputOutput(index, 'inputs')}>Remove Input</button>
                </div>
            ))}
            <button type="button" onClick={() => addInputOutput('inputs')}>Add Input</button>
            </div>
    
            {/* Outputs */}
            <div className="transaction-outputs">
            <h3>Outputs</h3>
            {transactionData.outputs.map((output, index) => (
                <div key={index} className="output">
                    <label>Value:</label>
                    <input type="number" name="value" value={output.value} onChange={e => handleInputChange(index, e, 'outputs')} />
                    <label>USD Value:</label>
                    <input type="number" name="value_usd" value={output.value_usd} onChange={e => handleInputChange(index, e, 'outputs')} />
                    <label>Recipient:</label>
                    <input type="text" name="recipient" value={output.recipient} onChange={e => handleInputChange(index, e, 'outputs')} />
                    <button type="button" className="red" onClick={() => removeInputOutput(index, 'outputs')}>Remove Output</button>
                </div>
            ))}
            <button type="button" onClick={() => addInputOutput('outputs')}>Add Output</button>
            </div>
        </div>

        <div className="field">
            <label>Time Until First Confirmation (minutes):</label>
            <input type="number" name="timeUntilFirstConfirmation" value={transactionData.timeUntilFirstConfirmation} onChange={handleGeneralChange} />
        </div>
        <div className="field">
            <label>Time Between Additional Confirmations (minutes):</label>
            <input type="number" name="timeBetweenConfirmations" value={transactionData.timeBetweenConfirmations} onChange={handleGeneralChange} />
        </div>
  
        <button type="submit" disabled={isLoading}>Create Transaction</button>
        {error && <p>{error}</p>}
        {isSuccess && <p style={{marginTop: '1rem'}}>Transaction created successfully! <a style={{color: '#21DFF3'}} href={`${process.env.REACT_APP_API_URL}/${transactionData.chain}/transaction/${transactionData.hash}`} target="_BLANK" rel="noreferrer">Link</a></p>}
      </form>
    </div>
  );  
};

export default CreateTransaction;