import React, { useState } from "react";
import './Login.css';
import { Link } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import { Loader } from "../../components/loader/Loader";

export const Login = (): JSX.Element => {

    const { handleLogin, error, isLoading } = useLogin();

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [validationErrors, setValidationErrors] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const validateForm = () => {
        const errors = {
            email: '',
            password: ''
        };

        if (!user.email)
            errors.email = 'Email krävs.';

        if (!user.password)
            errors.password = 'Lösenord krävs.';

        setValidationErrors(errors);

        return !errors.email && !errors.password;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateForm()) {
            await handleLogin(user.email, user.password);
        }
    }

    return (
        <section className="login auth">
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <h1 className="text-center mt-5">Sign in</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input type="email"
                                    className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                                    name="email"
                                    placeholder="Enter email"
                                    onChange={handleInputChange} />
                                {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password"
                                    className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleInputChange} />
                                {validationErrors.password && <div className="invalid-feedback">{validationErrors.password}</div>}
                            </div>
                            <div className="button-wrap mt-4">
                                <button className="button primary" type="submit">Log in</button>
                            </div>
                            {error && <p className="text-center mt-3 text-danger">{error}</p>}
                            {isLoading && <Loader />}
                            <p className="text-center mt-3 text-muted">
                                Don't have an account? <Link to="/register">Create one.</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );    
}