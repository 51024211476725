import React from 'react';

import './Info.css';

export const Info: React.FC = (): JSX.Element => {
    return (
        <div className="hp-about-wrap" 
             style={{backgroundImage: "url('/assets/img/gradient-trapezoid.svg')"}}>
            <div className="hp-about-content">
                <div className="hp-about__text">
                    <div className="h1">
                        We develop products<br />
                        that make blockchain data<br />
                        accessible to individuals,<br />
                        development teams, and research organizations 
                    </div>
                    <p className="p"> 
                        Blockchair is the first blockchain explorer which incorporates a multitude of different blockchains into one search engine.
                        <br/><br/>
                        Now, we are striving to make blockchain data understandable and accessible for a wide and varied audience, interested in both blockchain and crypto, 
                        while maintaining and securing the privacy of our users as a paramount when developing products. 
                    </p>
                </div>
                <img src="https://loutre.blockchair.io/assets/images/abstract/about.svg" className="hp-about__graphics" alt="About logo" loading="lazy" width="540" height="540" />
            </div>
        </div>
    )
}