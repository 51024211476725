import React, { useState } from "react";
import User from "../../interfaces/user";
import { Link } from "react-router-dom";
import { useRegister } from "../../hooks/useRegister";

import './Register.css';
import { Loader } from "../../components/loader/Loader";

export const Register = (): JSX.Element => {

    const { signup, error, isLoading } = useRegister();
    const [user, setUser] = useState<User>({
        email: '',
        password: ''
    });
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const validate = () => {
        const errors: Record<string, string> = {};
        if (!user.email.includes("@")) 
            errors.email = "The email address is invalid.";
        if (!user.password)
            errors.password = "Password is required.";
        if (user.password && user.password.length < 8)
                errors.password = "Password must be at least 8 characters long.";
        if (user.password !== confirmPassword) 
            errors.confirmPassword = "Passwords do not match.";

        return errors;
    };

    const register_user = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("CALLED")
        
        const errors = validate();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        
        setValidationErrors({});
        await signup(user);
    };

    return (
        <section className="register auth">
            <div className="container">
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <h1 className="text-center mt-5 title">Register</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <form onSubmit={register_user}>
                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input type="email"
                                    className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                                    name="email"
                                    placeholder="Enter email"
                                    onChange={handleInputChange} />
                                {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password"
                                    className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleInputChange} />
                                {validationErrors.password && <div className="invalid-feedback">{validationErrors.password}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm password</label>
                                <input type="password"
                                    className={`form-control ${validationErrors.confirmPassword ? 'is-invalid' : ''}`}
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    onChange={handleConfirmPasswordChange} />
                                {validationErrors.confirmPassword && <div className="invalid-feedback">{validationErrors.confirmPassword}</div>}
                            </div>
                            <div className="button-wrap">
                            <button className="button primary" type="submit">Register</button>
                            </div>
                            {error && <p className="text-center mt-3 text-danger">{error}</p>}
                            {isLoading && <Loader />}
                            <p className="text-center mt-3 text-muted">
                                Already have an account? <Link to="/login">Sign in.</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}