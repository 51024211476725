import { useState } from 'react';
import axios from 'axios';

export const useDeleteTransaction = () => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const deleteTransaction = async (transactionId: string) => {
        setIsLoading(true);
        setError('');
        setIsSuccess(false);

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/admin/transaction/${transactionId}`, {
                withCredentials: true,
            });
            setIsSuccess(true);
            setIsLoading(false);
        } catch (err: any) {
            if (err.response && err.response.data) {
                setError(err.response.data.message); 
            } else {
                setError('Error deleting the transaction');
            }
            setIsLoading(false);
        }
    };

    return { deleteTransaction, error, isLoading, isSuccess };
};