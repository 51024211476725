import React from 'react';

import './Footer.css';

export const Footer = () => {
    return (
        <footer>
            <div className="container-1280">
                <div className="footer-columns">
                    <div className="mobile-column">
                        <a href="/about" target="_self"> About Blockchair </a>
                        <a href="/faq" target="_self"> FAQ </a>
                        <a href="/changelog" target="_self"> Changelog </a>
                        <a href="/careers" target="_self"> Careers </a>
                        <a href="/terms" target="_self"> Terms of service </a>
                        <div className="socials">
                            <a href="https://twitter.com/Blockchair" target="_blank" rel="noreferrer" className="d-inline-block"><img src="https://loutre.blockchair.io/assets/svg/twitter.svg" alt="Twitter" width="24" /></a>
                            <a href="https://t.me/BlockchairNews" target="_blank" rel="noreferrer" className="d-inline-block"><img src="https://loutre.blockchair.io/assets/svg/telegram.svg" alt="Telegram" width="24" /></a>
                            <a href="https://github.com/Blockchair" target="_blank" rel="noreferrer" className="d-inline-block"><img src="https://loutre.blockchair.io/assets/svg/github.svg" alt="GitHub" width="24" /></a>
                            <a href="https://www.linkedin.com/company/blockchair/" target="_blank" rel="noreferrer" className="d-inline-block"><img src="https://loutre.blockchair.io/assets/svg/linkedin.svg" alt="LinkedIn" width="24" /></a>
                        </div>
                    </div>
                    <div className="column">
                        <h3 className="h3">Explorers</h3>
                        <a href="https://blockchair.com/bitcoin">Bitcoin</a>
                        <a href="https://blockchair.com/ethereum" target="_self"> Ethereum </a>
                        <a href="https://blockchair.com/bnb" target="_self"> BNB </a>
                        <a href="https://blockchair.com/opbnb" target="_self"> opBNB </a>
                        <a href="https://blockchair.com/litecoin" target="_self"> Litecoin </a>
                        <a href="https://blockchair.com/cardano" target="_self"> Cardano </a>
                        <a href="https://blockchair.com/ripple" target="_self"> Ripple </a>
                        <a href="https://blockchair.com/polkadot" target="_self"> Polkadot </a>
                        <a href="https://blockchair.com/dogecoin" target="_self"> Dogecoin </a>
                        <a href="https://blockchair.com/bitcoin-cash" target="_self"> Bitcoin Cash </a>
                        <a href="https://blockchair.com/stellar" target="_self"> Stellar </a>
                        <a href="https://blockchair.com/monero" target="_self"> Monero </a>
                        <a href="https://blockchair.com/kusama" target="_self"> Kusama </a>
                        <a href="https://blockchair.com/ecash" target="_self"> eCash </a>
                        <a href="https://blockchair.com/zcash" target="_self"> Zcash </a>
                        <a href="https://blockchair.com/dash" target="_self"> Dash </a>
                        <a href="https://blockchair.com/mixin" target="_self"> Mixin </a>
                        <a href="https://blockchair.com/groestlcoin" target="_self"> Groestlcoin </a>
                        <hr color='#8191b5' style={{height: '.0001rem', opacity: '.2', margin: '10px 0', display: 'block', width: '50%'}}/>
                        <br />
                        <a href="https://blockchair.com/tether" target="_self"> Tether USD </a>
                        <a href="https://blockchair.com/usd-coin" target="_self"> USD Coin </a>
                        <a href="https://blockchair.com/binance-usd" target="_self"> Binance USD </a>
                    </div>
                    <div className="column">
                        <h3 className="h3">Data</h3>
                        <a href="https://blockchair.com/api" target="_self"> API </a>
                        <a href="https://blockchair.com/dumps#database" target="_self"> Datasets </a>
                        <a href="https://blockchair.com/charts" target="_self"> Charts </a>
                        <a href="https://blockchair.com/whois" target="_self"> ENS Lookup</a>
                        <h3 className="h3">Services</h3>
                        <a href="https://blockchair.com/news" target="_self"> Blockchair News </a>
                        <a href="https://blockchair.com/donut" target="_self"> Blockchair Donut </a>
                        <a href="https://blockchair.com/awesome" target="_self"> Blockchair Awesome </a>
                        <h3 className="h3">Products</h3>
                        <a className="footer__link" href="https://blockchair.com/pdf" target="_self"> Transaction receipts </a>
                        <a className="footer__link" href="https://blockchair.com/address/statement" target="_self"> Wallet statements </a>
                        <a className="footer__link" href="https://blockchair.com/markets" target="_self"> Portfolio tracker </a>
                        <a className="footer__link" href="https://blockchair.com/broadcast" target="_self"> Broadcast transaction </a>
                        <a className="footer__link" href="https://blockchair.com/bitcoin/privacy-o-meter" target="_self"> Privacy-o-meter </a>
                        <a className="footer__link" href="https://blockchair.com/nodes" target="_self"> Node explorers </a>
                        <a className="footer__link" href="https://blockchair.com/tools/release-monitor" target="_self"> Release monitor </a>
                        <a className="footer__link" href="https://blockchair.com/tools/halving-countdown" target="_self"> Halving countdown </a>
                        <a className="footer__link" href="https://blockchair.com/compare" target="_self"> Compare blockchains </a>
                        <a className="footer__link" href="https://blockchair.com/extensions" target="_self"> Get Blockchair extension </a>        
                    </div>
                    <div className="column">
                        <h3 className="h3">Useful links</h3>
                        <a href="https://blockchair.com/about" target="_self"> About Blockchair </a>
                        <a href="https://blockchair.com/faq" target="_self"> FAQ </a>
                        <a href="https://blockchair.com/changelog" target="_self"> Changelog </a>
                        <a href="https://blockchair.com/careers" target="_self"> Careers </a>
                        <a href="https://blockchair.com/terms" target="_self"> Terms of service </a>
                        <a href="https://blockchair.com/privacy" target="_self"> Privacy policy </a>
                        <a href="http://blkchairbknpn73cfjhevhla7rkp4ed5gg2knctvv7it4lioy22defid.onion" target="_blank" rel="noreferrer" > Blockchair Onion v3 URL </a>
                        <h3 className="h3">For partners</h3>
                        <a href="https://blockchair.com/partnerships" target="_self"> Partnerships </a>
                        <a href="https://blockchair.com/advertise" target="_self"> Advertise with us </a>
                        <a href="https://blockchair.com/brand-kit" target="_self"> Brand kit </a>
                        <h3 className="h3">For developers</h3>
                        <a href="https://github.com/Blockchair/Blockchair.Support/issues" target="_blank" rel="noreferrer" > Submit a bug or request </a>
                        <a href="https://blockchair.com/bug-bounty" target="_self"> Bug bounty program </a>
                        <a href="https://blockchair.com/api/docs" target="_self"> API documentation </a>
                        <a href="https://status.blockchair.com/" target="_blank" rel="noreferrer" > Status </a>                        
                    </div>
                    <div className="column">
                        <h3 className="h3">Social</h3>
                        <a href="https://twitter.com/Blockchair" target="_blank" rel="noreferrer" > Twitter </a>
                        <a href="https://t.me/BlockchairNews" target="_blank" rel="noreferrer" > Telegram </a>
                        <a href="https://github.com/Blockchair" target="_blank" rel="noreferrer" > GitHub </a>
                        <a href="https://www.linkedin.com/company/blockchair/" target="_blank" rel="noreferrer" > LinkedIn </a>

                    </div>
                    <div className="column">
                        <h3 className="h3">Languages</h3>
                        <a className="d-flex ai-center pointer-events-none" href="/"> English <span className="d-flex ai-center ml-5 check"><svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.9477 0.410031C14.3796 0.818308 14.3987 1.49938 13.9905 1.93125L5.52662 10.8841L0.424154 6.99807C-0.0486455 6.63799 -0.140019 5.9628 0.220065 5.49C0.580149 5.0172 1.25533 4.92583 1.72813 5.28592L5.29164 7.99988L12.4265 0.452743C12.8348 0.0208757 13.5159 0.00175273 13.9477 0.410031Z" fill="#2170FF"></path></svg></span></a>
                        <a className="d-flex ai-center" href="/es"> Español </a>
                        <a className="d-flex ai-center" href="/fr"> Français </a>
                        <a className="d-flex ai-center" href="/it"> Italiano </a>
                        <a className="d-flex ai-center" href="/nl"> Nederlands </a>
                        <a className="d-flex ai-center" href="/pt"> Português </a>
                        <a className="d-flex ai-center" href="/ru"> Русский </a>
                        <a className="d-flex ai-center" href="/zh"> 中文 </a>
                        <a className="d-flex ai-center" href="/fa"> فارسی </a>
                        <a className="d-flex ai-center" href="/id"> Вahasa Indonesia </a>
                        <a className="d-flex ai-center" href="/tr"> Türkçe </a>
                        <a className="d-flex ai-center" href="/ja"> 日本語 </a>
                        <a className="d-flex ai-center" href="/ko"> 한국어 </a>
                        <a className="d-flex ai-center" href="/de"> Deutsch </a>
                    </div>
                </div>
                <div className="footer__row">
                    <span className="txt-basic footer__row__copyright"> © 2024 Blockchair </span>
                    <img className="footer__row__icon footer__row__logo" src="https://loutre.blockchair.io/assets/svg/footer-logo.svg" alt="Footer Blockchair logo" loading="lazy" width="120" height="0" />
                    <img className="footer__row__icon footer__row__no-trackers" src="https://loutre.blockchair.io/assets/svg/footer-no-trackers.svg" alt="No 3d party trackers" loading="lazy" width="0" height="35" />
                    <img className="footer__row__icon footer_img_js" src="https://loutre.blockchair.io/assets/svg/footer-nojs.svg" alt="Works without javascript" loading="lazy" width="0" height="35" />
                    <span className="footer__row__version">2.0.2-295-g6ff16b493 [72] Thu 16 Oct 2024 10:41:41 GMT</span>
                </div>
            </div> 
        </footer>

    )
}