import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTransaction } from '../../hooks/useGetTransaction';
import './Transaction.css';
import { News } from '../home/news/News';
import { TransactionNavbar } from '../../components/transaction-navbar/TransactionNavbar';
import { Helmet } from 'react-helmet';

type ShortForm = {
    [key: string]: string;
};

const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    });
  };

function timeAgo(dateString: Date) {
    const transactionDate = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = Math.floor((now.getTime() - transactionDate.getTime()) / 1000);

    if (differenceInSeconds < 60) {
        return `${differenceInSeconds} seconds ago`;
    } else if (differenceInSeconds < 3600) {
        return `${Math.floor(differenceInSeconds / 60)} minutes ago`;
    } else if (differenceInSeconds < 86400) {
        return `${Math.floor(differenceInSeconds / 3600)} hours ago`;
    } else if (differenceInSeconds < 604800) {
        return `${Math.floor(differenceInSeconds / 86400)} days ago`;
    } else {
        return `${Math.floor(differenceInSeconds / 604800)} weeks ago`;
    }
}

export const Transaction: React.FC = (): JSX.Element => {
    const { chain, transactionId } = useParams();
    const { transaction, getTransaction, error, isLoading, setTransaction } = useGetTransaction();
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const SATOSHI_CONVERSION: number = 0.00000001;
    const WEI_CONVERSION: number = 1e-18;

    const short_form: ShortForm = {
        bitcoin: 'BTC',
        litecoin: 'LTC',
        ethereum: 'ETH'
    };    

    useEffect(() => {
        const fetchData = async () => {
            console.log('chain: ', chain);  
            if (chain && transactionId) {
                let data = await getTransaction(chain, transactionId);
                if (data) {
                    setTransaction(data);
                }
            }
        };
    
        fetchData();
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain, transactionId]);

    useEffect(() => {
        let intervalId: any;
    
        const fetchContext = async () => {
            if (!chain || !transactionId) return;
            if (transaction && transaction.context?.api) return;
            console.log('fetching context')
            const newData = await getTransaction(chain, transactionId);
            setTransaction(newData);
        };
    
        if (transaction && !transaction.context?.api) {
            intervalId = setInterval(fetchContext, 5000);
        }
    
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain, transactionId, transaction]);

    useEffect(() => {
        const drawGraph = () => {
            if (!transaction) return;
            if (!canvasRef.current) return;
            const canvas = canvasRef.current as HTMLCanvasElement;
            const ctx = canvas.getContext('2d');
    
            if (ctx) {
                // Clear previous drawing
                ctx.clearRect(0, 0, canvas.width, canvas.height);
    
                // Your data points
                const data = [65, 59, 65, 81, 56, 55, 43, 37, 45, 67, 60, 53, 55, 63, 60, 55, 48, 50, 40, 42, 46, 52, 48, 59];
    
                // Calculating max value for scaling
                const maxValue = Math.max(...data);
    
                // Drawing the line graph
                ctx.beginPath();
                ctx.moveTo(0, canvas.height - (data[0] / maxValue) * canvas.height);
                data.forEach((value, index) => {
                    const x = (canvas.width / (data.length - 1)) * index;
                    const y = canvas.height - (value / maxValue) * canvas.height;
                    ctx.lineTo(x, y);
                });
                ctx.strokeStyle = '#2170ff';
                ctx.lineWidth = 2;
                ctx.stroke();
            }
        };
    
        drawGraph();
    }, [ transaction ]);

    if (isLoading || !transaction || !chain) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const transactionHash = Object.keys(transaction.data)[0];
    const transactionData = transaction.data[transactionHash];

    return (
        <>
        <Helmet>
            <meta name="description" content={`${chain?.charAt(0).toUpperCase() + chain?.slice(1)} (${short_form[chain]}) transaction ${transactionHash}, date: ${formatDate(transactionData.transaction.time)}`} />
            <meta name="og:description" content={`${chain?.charAt(0).toUpperCase() + chain?.slice(1)} (${short_form[chain]}) transaction ${transactionHash}, date: ${formatDate(transactionData.transaction.time)}`} />
            <meta name="og:title" content={`${chain?.charAt(0).toUpperCase() + chain?.slice(1)} / Transaction / ${transactionHash}`} />
            <meta name="twitter:description" content={`${chain?.charAt(0).toUpperCase() + chain?.slice(1)} (${short_form[chain]}) transaction ${transactionHash}, date: ${formatDate(transactionData.transaction.time)}`} />
            <meta name="twitter:title" content={`${chain?.charAt(0).toUpperCase() + chain?.slice(1)} / Transaction / ${transactionHash}`} />
            <title>{chain?.charAt(0).toUpperCase() + chain?.slice(1)} / Transaction / {transactionHash}</title>
        </Helmet>
    <div className="transaction-wrap bg--coin pt-30 page-transaction-litecoin">
        <TransactionNavbar />
      <div className="container-1280 page-transaction-areas" id="page-transaction-areas">
        <section className="transaction__header">
          <div className="breadcrumbs-wrap">
            <img className="breadcrumbs__logo flex-shrink-0 flex-grow-0 display-block"
              src={`https://loutre.blockchair.io/assets/svg/chains/${chain?.toLowerCase()}.svg?_nocache=2`}
              alt="transaction" />
            <div className="breadcrumbs__data">
                <nav className="txt-basic">
                    <a href={`https://blockchair.com/${chain}`} className="bread-link">{chain?.charAt(0).toUpperCase() + chain?.slice(1)}</a>
                    <span className="breadcrumbs__dot dot-grayblue-3px"></span>
                    <a href={`https://blockchair.com/${chain}/transactions`} className="bread-link">Transactions</a>
                </nav>
                <h1 className="breadcrumps__title h4" style={{ color: "#fff" }}>
                    {chain?.charAt(0).toUpperCase() + chain?.slice(1)} transaction
                    <div className="popup-local p-relative popup-local--bottom d-iblock lh-100">
                    <input id="c_aggKL2VM" className="popup-local__checkbox" type="checkbox" />
                    <label htmlFor="c_aggKL2VM" className="popup-local__overlay"></label>
                    <label htmlFor="c_aggKL2VM" className="popup-local__toggle">
                        <a 
                        className="breadcrumbs__apiurl bgc-plain b--def c-txt-quiet hover-link hover-pointer fw-400 popup-local__toggle-accent" 
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.blockchair.com/${chain.toLowerCase()}/dashboards/transaction/${transactionHash}`}
                        >
                        API
                        </a>
                    </label>
                    </div>
                </h1>
                </div>
          </div>
        </section>
        <div className="transaction__hash-wrap mb-10-mobile mt-10-mobile">
            <div className="content-wrap b--def shadow-block real-hash">
                <div className="content-wrap-hat--short transaction__hash-wrap__hat">
                <div className="hash-exp__title txt-basic d-flex ai-center c-txt-quiet-gray">
                    Transaction hash
                </div>
                <div className="hash">
                    {transactionHash}
                    <span className="d-iblock va-mid hash__copy hover-pointer data-v-tooltip v-tooltip-placement-top" data-v-tooltip-clicked="Copied" data-v-tooltip="Click to copy">
                        <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.25">
                                <rect x="5" y="1" width="14" height="14" rx="3" stroke="#2170ff" strokeWidth="2"></rect>
                                <path fillRule="evenodd" clipRule="evenodd" d="M4 4C1.79086 4 0 5.79086 0 8V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16H14C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6V4Z" fill="#2170ff"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                </div>
                <div className="content-wrap-body--short">
                    <div className="transaction-costs w-100 d-grid">
                        <div>
                        <div className="transaction-costs__caption txt-basic d-flex ai-center">
                            Amount transacted 
                            <span className="b-questionmark d-iflex ai-center ml-5 data-v-tooltip v-tooltip-placement-top" 
                                data-v-tooltip="USD value at the time of transaction"
                                >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#EFF2F9"></circle><circle cx="9" cy="9" r="8.5" stroke="white" stroke-opacity="0.5"></circle><path d="M9.09716 4.5C9.95396 4.5 10.6386 4.7352 11.151 5.2056C11.6718 5.676 11.9322 6.3186 11.9322 7.1334C11.9322 7.9818 11.6634 8.6202 11.1258 9.0486C10.5882 9.477 9.87416 9.6912 8.98376 9.6912L8.93336 10.6866H7.68596L7.62296 8.7084H8.03876C8.85356 8.7084 9.47516 8.5992 9.90356 8.3808C10.3404 8.1624 10.5588 7.7466 10.5588 7.1334C10.5588 6.6882 10.4286 6.3396 10.1682 6.0876C9.91616 5.8356 9.56336 5.7096 9.10976 5.7096C8.65616 5.7096 8.29916 5.8314 8.03876 6.075C7.77836 6.3186 7.64816 6.6588 7.64816 7.0956H6.29996C6.29996 6.5916 6.41336 6.1422 6.64016 5.7474C6.86696 5.3526 7.19036 5.046 7.61036 4.8276C8.03876 4.6092 8.53436 4.5 9.09716 4.5ZM8.29076 13.4712C8.03036 13.4712 7.81196 13.383 7.63556 13.2066C7.45916 13.0302 7.37096 12.8118 7.37096 12.5514C7.37096 12.291 7.45916 12.0726 7.63556 11.8962C7.81196 11.7198 8.03036 11.6316 8.29076 11.6316C8.54276 11.6316 8.75696 11.7198 8.93336 11.8962C9.10976 12.0726 9.19796 12.291 9.19796 12.5514C9.19796 12.8118 9.10976 13.0302 8.93336 13.2066C8.75696 13.383 8.54276 13.4712 8.29076 13.4712Z" fill="#9CA7BC"></path></svg>
                            </span>
                        </div>
                        <div className="transaction-costs__values font-p fs-15 medium ls-2 d-flex ai-center fw-wrap">
                            <span className="transaction-costs__value ws-nw">
                                <span className="value-wrapper d-iflex ai-center" >
                                {chain === 'bitcoin' && <span className="">{(transactionData.transaction.input_total * SATOSHI_CONVERSION).toFixed(8)}</span>}
                                {chain === 'ethereum' && <span className="">{(transactionData.transaction.value * WEI_CONVERSION).toFixed(4)}</span>}
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: "keep-all" }}> {short_form[chain]}</span>
                                </span>
                                <span className="ai-center d-none">
                                
                                
                                </span>
                            </span>
                            <span className="transaction-costs__dot dot-grayblue-3px"></span>
                            <span className="transaction-costs__value ws-nw">
                                <span className="value-wrapper d-iflex ai-center" >
                                {chain === 'bitcoin' && <span className="">{(parseFloat(transactionData.transaction.input_total_usd)).toFixed(2)}</span>}
                                {chain === 'ethereum' && <span className="">{(parseFloat(transactionData.transaction.value_usd)).toFixed(2)}</span>}
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: "keep-all" }}> USD</span>
                                </span>
                                <span className="ai-center d-none">
                                <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: "rotate(180deg)" }}>
                                    <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="var(--c-red-h)"></path>
                                </svg>
                                
                                </span>
                            </span>
                            </div>
                        </div>

                        <div>
                        <div className="transaction-costs__caption txt-basic d-flex ai-center">
                            Transaction fee 
                            <span className="b-questionmark d-iflex ai-center ml-5 data-v-tooltip v-tooltip-placement-top" 
                                data-v-tooltip="USD value at the time of transaction"
                                >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#EFF2F9"></circle><circle cx="9" cy="9" r="8.5" stroke="white" stroke-opacity="0.5"></circle><path d="M9.09716 4.5C9.95396 4.5 10.6386 4.7352 11.151 5.2056C11.6718 5.676 11.9322 6.3186 11.9322 7.1334C11.9322 7.9818 11.6634 8.6202 11.1258 9.0486C10.5882 9.477 9.87416 9.6912 8.98376 9.6912L8.93336 10.6866H7.68596L7.62296 8.7084H8.03876C8.85356 8.7084 9.47516 8.5992 9.90356 8.3808C10.3404 8.1624 10.5588 7.7466 10.5588 7.1334C10.5588 6.6882 10.4286 6.3396 10.1682 6.0876C9.91616 5.8356 9.56336 5.7096 9.10976 5.7096C8.65616 5.7096 8.29916 5.8314 8.03876 6.075C7.77836 6.3186 7.64816 6.6588 7.64816 7.0956H6.29996C6.29996 6.5916 6.41336 6.1422 6.64016 5.7474C6.86696 5.3526 7.19036 5.046 7.61036 4.8276C8.03876 4.6092 8.53436 4.5 9.09716 4.5ZM8.29076 13.4712C8.03036 13.4712 7.81196 13.383 7.63556 13.2066C7.45916 13.0302 7.37096 12.8118 7.37096 12.5514C7.37096 12.291 7.45916 12.0726 7.63556 11.8962C7.81196 11.7198 8.03036 11.6316 8.29076 11.6316C8.54276 11.6316 8.75696 11.7198 8.93336 11.8962C9.10976 12.0726 9.19796 12.291 9.19796 12.5514C9.19796 12.8118 9.10976 13.0302 8.93336 13.2066C8.75696 13.383 8.54276 13.4712 8.29076 13.4712Z" fill="#9CA7BC"></path></svg>
                            </span>
                        </div>
                        <div className="transaction-costs__values font-p fs-15 medium ls-2 d-flex ai-center fw-wrap">
                            <span className="transaction-costs__value ws-nw">
                            <span className="value-wrapper d-iflex ai-center" >
                                {chain === 'bitcoin' && <span className="">{(transactionData.transaction.fee * SATOSHI_CONVERSION).toFixed(8)}</span>}
                                {chain === 'ethereum' && <span className="">{(transactionData.transaction.fee * WEI_CONVERSION).toFixed(12)}</span>}
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: "keep-all" }}> {short_form[chain.toLowerCase()]}</span>
                            </span>
                            <span className="ai-center d-none">
                                {/* SVG content here */}
                            </span>
                            </span>
                            <span className="transaction-costs__dot dot-grayblue-3px"></span>
                            <span className="transaction-costs__value ws-nw">
                            <span className="value-wrapper d-iflex ai-center" >
                                <span className="">{parseFloat(transactionData.transaction.fee_usd).toFixed(2)}</span>
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: "keep-all" }}> USD</span>
                            </span>
                            <span className="ai-center d-none">
                                {/* SVG content here */}
                            </span>
                            </span>
                        </div>
                        </div>
                        <div>
                        {chain === 'bitcoin' && (<><div className="transaction-costs__caption txt-basic">
                            Fee per vbyte
                        </div>
                        <div className="transaction-costs__values font-p fs-15 medium ls-2 d-flex ai-center fw-wrap">
                            <span className="transaction-costs__value">
                            <span className="value-wrapper d-iflex ai-center">
                                <span className="">{parseFloat(transactionData.transaction.fee_per_kb).toFixed(2)}</span>
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: 'keep-all' }}> satoshi</span>
                            </span>
                            <span className="ai-center d-none">
                                <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="var(--c-red-h)"></path>
                                </svg>
                                
                            </span>
                            </span>
                        </div></>)}
                        <div>
                        <div className="transaction-costs__values time-wrap font-p fs-15 medium ls-2 d-flex ai-center fw-wrap transaction-costs__values--astitle">
                            <span className="transaction-costs__value ws-nw">
                            <span className="value-wrapper d-iflex ai-center">
                                <span className="">{timeAgo(transactionData.transaction.time)}</span>
                            </span>
                            <span className="ai-center d-none">
                                <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                    <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="var(--c-red-h)"></path>
                                </svg>
                            </span>
                            </span>
                            <span className="transaction-costs__dot dot-grayblue-3px"></span>
                            <br />
                            <span className="transaction-costs__value ws-nw">
                            <span className="value-wrapper d-iflex ai-center">
                                <span className="">{formatDate(transactionData.transaction.time)}</span>
                                <span className="ml-5"></span>
                                <span style={{ wordBreak: 'keep-all' }}> UTC</span>
                            </span>
                            <span className="ai-center d-none">
                                <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="var(--c-red-h)"></path>
                                </svg>
                                
                            </span>
                            </span>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="tr-mid ac-start ui-tabs-content ui-tabs-content--transaction-mid">
            {/* More nested divs and elements here */}
            <div className="content-wrap b--def shadow-block sm-order-0 tx-status-wrap" id="tx-status-wrap">
                <div className="content-wrap-hat">
                    <div className="transaction-status">
                    {transaction.context.state - transactionData.transaction.block_id >= 6 && <div className="circle">
                        <div className="checkmark"></div>
                    </div>}
                    {transaction.context.state - transactionData.transaction.block_id === 0 && <div className="loading-circle">
                         <div className="tx-status__dots">
                            <div className="tx-status__dots__item" id="dot1"></div>
                            <div className="tx-status__dots__item" id="dot2"></div>
                            <div className="tx-status__dots__item" id="dot3"></div>
                        </div>
                    </div>}
                    {
                        transaction.context.state - transactionData.transaction.block_id !== 0 && 
                        transaction.context.state - transactionData.transaction.block_id < 6 &&
                        <div
                            className="circle"
                            style={{
                                backgroundImage: `conic-gradient(#5bff21 0 0deg, transparent ${60 * (transaction.context.state - transactionData.transaction.block_id)}deg, #2170ff 360deg)`
                            }}
                        ></div>
                    }
                    <div className="transaction-status__status ml-15">
                        <span className="transaction-status__caption txt-basic c-grayblue">Transaction status</span>
                        <span className="transaction-status__confirmations h6 d-none">
                            {transaction.context.state - transactionData.transaction.block_id > 0 && <span className="transaction-status__confirmations__header">Confirmed</span>}
                            {transaction.context.state - transactionData.transaction.block_id === 0 && <span className="transaction-status__confirmations__header">Waiting for confirmations</span>}
                            <span className="transaction-status__dot dot-grayblue-3px mlr-5"></span>
                            {transaction.context.state - transactionData.transaction.block_id > 0 && <span className="transaction-status__confirmations__count"> {(transaction.context.state - transactionData.transaction.block_id).toLocaleString()} confirmations</span>}
                            {transaction.context.state - transactionData.transaction.block_id === 0 && <span className="transaction-status__confirmations__count"> 0 of 6</span>}
                            <span className="b-questionmark d-iflex ai-center ml-5 data-v-tooltip v-tooltip-placement-top" 
                                data-v-tooltip="USD value at the time of transaction" style={{opacity: '.3', marginLeft: '0px'}}
                                >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="9" fill="#EFF2F9"></circle><circle cx="9" cy="9" r="8.5" stroke="white" stroke-opacity="0.5"></circle><path d="M9.09716 4.5C9.95396 4.5 10.6386 4.7352 11.151 5.2056C11.6718 5.676 11.9322 6.3186 11.9322 7.1334C11.9322 7.9818 11.6634 8.6202 11.1258 9.0486C10.5882 9.477 9.87416 9.6912 8.98376 9.6912L8.93336 10.6866H7.68596L7.62296 8.7084H8.03876C8.85356 8.7084 9.47516 8.5992 9.90356 8.3808C10.3404 8.1624 10.5588 7.7466 10.5588 7.1334C10.5588 6.6882 10.4286 6.3396 10.1682 6.0876C9.91616 5.8356 9.56336 5.7096 9.10976 5.7096C8.65616 5.7096 8.29916 5.8314 8.03876 6.075C7.77836 6.3186 7.64816 6.6588 7.64816 7.0956H6.29996C6.29996 6.5916 6.41336 6.1422 6.64016 5.7474C6.86696 5.3526 7.19036 5.046 7.61036 4.8276C8.03876 4.6092 8.53436 4.5 9.09716 4.5ZM8.29076 13.4712C8.03036 13.4712 7.81196 13.383 7.63556 13.2066C7.45916 13.0302 7.37096 12.8118 7.37096 12.5514C7.37096 12.291 7.45916 12.0726 7.63556 11.8962C7.81196 11.7198 8.03036 11.6316 8.29076 11.6316C8.54276 11.6316 8.75696 11.7198 8.93336 11.8962C9.10976 12.0726 9.19796 12.291 9.19796 12.5514C9.19796 12.8118 9.10976 13.0302 8.93336 13.2066C8.75696 13.383 8.54276 13.4712 8.29076 13.4712Z" fill="#9CA7BC"></path></svg>
                            </span>
                            {/* <span className="transaction-status__segwit-badge fs-12 medium lh-100 ml-5">SegWit</span> */}
                        </span>
                        <span className="transaction-status__priority d-flex fw-wrap ai-center h7 d-none txt-micro">
                            Block id <span className="num-blocks">{transactionData.transaction.block_id.toLocaleString()}</span>
                        </span>
                        </div>
                    </div>
                </div>
                <div className="content-wrap-body--addinfo">
                    <div className="transaction-addinfo">
                        <div className="d-flex ai-center fw-wrap font-h fs-14 regular">
                            <label className="transaction-addinfo__expand-container transaction-addinfo__additional__item txt-basic ml-5 inline-link" htmlFor="transaction-addinfo__expand-checkbox">
                                <span className="transaction-addinfo__expand-checkmark p-realtive d-flex ai-center mr-5">
                                    <svg viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 3.5L8.34742e-08 7L0 7.15493e-08L6 3.5Z" fill="#2170ff"></path>
                                    </svg>
                                </span> 
                                Additional info 
                            </label>
                            <div className="transaction-addinfo__expanded d-grid ta-left bgc-bright js-stretch font-h regular fs-13">
                                {/* Further elements here */}
                            </div>
                        </div>
                        <div className="transaction-addinfo__additional ml-auto d-flex ai-center">
                            <a className="d-iflex ai-center c-txt-main fs-14 transaction-addinfo__additional__item" href={`https://blockchair.com/${chain.toLowerCase()}/transaction/${transactionHash}.pdf`} target="_blank" rel="noreferrer noopener">
                                <div>
                                    <img height="22" src="https://loutre.blockchair.io/assets/images/file-pdf-blue.svg" style={{ marginBottom: '-1px' }} alt="X"/>
                                </div>
                                <span className="ml-5 inline-link transaction-addinfo__additional__caption txt-basic"> Transaction receipt </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrap all-addresses-wrap b--def shadow-block content-wrap--tr-mobile sm-order-1 js-txmid-addon">
                <div className="content-wrap-body content-wrap-body--tr-mobile">
                    <div className="transaction-io">
                        <div className="transaction-io__io-wrap">
                            <div className="transaction-io__cards-wrap d-grid w-100">
                                <div className="transaction-io__cards-col d-grid ac-start">
                                    <input type="checkbox" id="c_senders--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3" className="transaction-io__cards-col__checkbox" style={{ display: 'none' }} />
                                    <label className="transaction-io__total-counts__hat b--def font-h regular fs-13 c-txt-quiet" htmlFor="c_senders--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3">
                                        Senders 
                                        <span className="transaction-io__total-counts__bubble fs-12 ml-5 shadow-block br-8 bgc-bright font-p medium c-txt-quiet"> {transactionData.inputs ? transactionData.inputs.length : 1} </span>
                                    </label>
                                    <div className="transaction-io__cards-col__items d-grid ac-start" id="io-inputs--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3">
                                        { transactionData.inputs && 
                                            transactionData.inputs.map((input: any, index: number) => (
                                                <div key={index} className="transaction-io__card br-8 bgc-bright">
                                                    <div className="fs-14">
                                                        <div className="hash-wrap d-iblock va-mid font-mono regular lh-130">
                                                            <a className="hash mr-5 d-inline va-mid" href={`/${chain.toLowerCase()}/address/${input.recipient}`}>{input.recipient}</a>
                                                            <span className="d-iblock va-mid hash__copy hover-pointer data-v-tooltip v-tooltip-placement-top" data-v-tooltip-clicked="Copied" data-v-tooltip="Click to copy">
                                                                <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="0.25">
                                                                        <rect x="5" y="1" width="14" height="14" rx="3" stroke="#2170ff" strokeWidth="2"></rect>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4 4C1.79086 4 0 5.79086 0 8V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16H14C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6V4Z" fill="#2170ff"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="address-info-wrap d-flex ai-center fw-wrap font-p regular fs-13 ls-2 c-txt-main mt-5">
                                                        {/* Transaction information here */}
                                                        <span>
                                                            <span>{(input.value * SATOSHI_CONVERSION).toFixed(8)}</span>
                                                            <span>&nbsp;{short_form[chain.toLowerCase()]}</span>
                                                        </span>
                                                        <span className="dot-grayblue-3px mlr-5"></span>
                                                        <span>
                                                            <span>{parseFloat(input.value_usd).toFixed(2)}</span>
                                                            <span>&nbsp;USD</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {transactionData.transaction.sender &&
                                            (
                                                <div className="transaction-io__card br-8 bgc-bright">
                                                    <div className="fs-14">
                                                        <div className="hash-wrap d-iblock va-mid font-mono regular lh-130">
                                                            <a className="hash mr-5 d-inline va-mid" href={`/${chain.toLowerCase()}/address/${transactionData.transaction.sender}`}>{transactionData.transaction.sender}</a>
                                                            <span className="d-iblock va-mid hash__copy hover-pointer data-v-tooltip v-tooltip-placement-top" data-v-tooltip-clicked="Copied" data-v-tooltip="Click to copy">
                                                                <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="0.25">
                                                                        <rect x="5" y="1" width="14" height="14" rx="3" stroke="#2170ff" strokeWidth="2"></rect>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4 4C1.79086 4 0 5.79086 0 8V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16H14C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6V4Z" fill="#2170ff"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="address-info-wrap d-flex ai-center fw-wrap font-p regular fs-13 ls-2 c-txt-main mt-5">
                                                        {/* Transaction information here */}
                                                        <span>
                                                            <span>{(transactionData.transaction.value * WEI_CONVERSION).toFixed(4)}</span>
                                                            <span>&nbsp;{short_form[chain.toLowerCase()]}</span>
                                                        </span>
                                                        <span className="dot-grayblue-3px mlr-5"></span>
                                                        <span>
                                                            <span>{parseFloat(transactionData.transaction.value_usd).toFixed(2)}</span>
                                                            <span>&nbsp;USD</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="transaction-io__cards-col d-grid ac-start">
                                    <input type="checkbox" id="c_recipients--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3" className="transaction-io__cards-col__checkbox" style={{ display: 'none' }} />
                                    <label className="transaction-io__total-counts__hat b--def font-h regular fs-13 c-txt-quiet" htmlFor="c_recipients--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3">
                                        Recipients 
                                        <span className="transaction-io__total-counts__bubble fs-12 ml-5 shadow-block br-8 bgc-bright font-p medium fs-12 c-txt-quiet"> {transactionData.outputs ? transactionData.outputs.length : 1} </span>
                                    </label>
                                    <div className="transaction-io__cards-col__items d-grid ac-start" id="io-inputs--1e1d7ed092a519663c7a815f9f6500d0e45dd55d82f0517d98524e61462e4eb3">
                                    {transactionData.outputs &&
                                        transactionData.outputs.map((output: any, index: number) => (
                                            <div key={index} className="transaction-io__card br-8 bgc-bright">
                                                <div className="fs-14">
                                                    <div className="hash-wrap d-iblock va-mid font-mono regular lh-130">
                                                        <a className="hash mr-5 d-inline va-mid" href={`/${chain.toLowerCase()}/address/${output.recipient}`}>{output.recipient}</a>
                                                        <span className="d-iblock va-mid hash__copy hover-pointer data-v-tooltip v-tooltip-placement-top" data-v-tooltip-clicked="Copied" data-v-tooltip="Click to copy">
                                                                <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="0.25">
                                                                        <rect x="5" y="1" width="14" height="14" rx="3" stroke="#2170ff" strokeWidth="2"></rect>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4 4C1.79086 4 0 5.79086 0 8V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16H14C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6V4Z" fill="#2170ff"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                    </div>
                                                </div>
                                                <div className="address-info-wrap d-flex ai-center fw-wrap font-p regular fs-13 ls-2 c-txt-main mt-5">
                                                    {/* Transaction information here */}
                                                    <span>
                                                        <span>{(output.value * SATOSHI_CONVERSION).toFixed(8)}</span>
                                                        <span>&nbsp;{short_form[chain.toLowerCase()]}</span>
                                                    </span>
                                                    <span className="dot-grayblue-3px mlr-5"></span>
                                                    <span>
                                                        <span>{parseFloat(output.value_usd).toFixed(2)}</span>
                                                        <span>&nbsp;USD</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {transactionData.transaction.recipient &&
                                        (
                                            <div className="transaction-io__card br-8 bgc-bright">
                                                <div className="fs-14">
                                                    <div className="hash-wrap d-iblock va-mid font-mono regular lh-130">
                                                        <a className="hash mr-5 d-inline va-mid" href={`/${chain.toLowerCase()}/address/${transactionData.transaction.recipient}`}>{transactionData.transaction.recipient}</a>
                                                        <span className="d-iblock va-mid hash__copy hover-pointer data-v-tooltip v-tooltip-placement-top" data-v-tooltip-clicked="Copied" data-v-tooltip="Click to copy">
                                                                <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="0.25">
                                                                        <rect x="5" y="1" width="14" height="14" rx="3" stroke="#2170ff" strokeWidth="2"></rect>
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M4 4C1.79086 4 0 5.79086 0 8V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16H14C14 17.1046 13.1046 18 12 18H4C2.89543 18 2 17.1046 2 16V8C2 6.89543 2.89543 6 4 6V4Z" fill="#2170ff"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                    </div>
                                                </div>
                                                <div className="address-info-wrap d-flex ai-center fw-wrap font-p regular fs-13 ls-2 c-txt-main mt-5">
                                                    {/* Transaction information here */}
                                                    <span>
                                                        <span>{(transactionData.transaction.value * WEI_CONVERSION).toFixed(4)}</span>
                                                        <span>&nbsp;{short_form[chain.toLowerCase()]}</span>
                                                    </span>
                                                    <span className="dot-grayblue-3px mlr-5"></span>
                                                    <span>
                                                        <span>{parseFloat(transactionData.transaction.value_usd).toFixed(2)}</span>
                                                        <span>&nbsp;USD</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }
                                        {/* Additional elements here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="tr-right ac-start" id="page-right-block">
            <div className="chart-side f-vert-def">
                <a className="c-txt-main chart-side--chart" href={`https://blockchair.com/${chain.toLowerCase()}/charts/price`}>
                <div className="chart-side__header">
                    <div>
                    <h6 className="h6 lh-100">{short_form[chain.toLowerCase()]} Price</h6>
                    </div>
                    <div className="fs-14 font-h">
                    <span className="value-wrapper price-tag d-iflex ai-center">
                        <span className="wb-ba price">{(parseFloat(parseFloat(transaction.context.market_price_usd).toFixed(2))).toLocaleString()}</span>
                        <span className="ml-5"></span>
                        <span style={{ wordBreak: 'keep-all' }}> USD </span>
                    </span>
                    {/* <span className="ai-center d-none">
                        <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                        <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#ff2626"></path>
                        </svg>
                        <span className="ml-5 font-h medium fs-14 ls-5 lh-100 d-iflex ai-center" style={{ color: '#ff2626' }}> 0<span>%</span></span>
                    </span> */}
                    <span className="ai-center d-iflex">
                        <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#00cd5e"></path>
                        </svg>
                        <span className="ml-5 font-h medium fs-14 ls-5 lh-100" style={{ color: '#00cd5e', fontSize: '.7rem' }}> 0.9% </span>
                    </span>
                    </div>
                </div>
                <div className="chart-side__body">
                    <span className="fs-12 c-grayblue last-month">Last month</span>
                    <div className="chart-side__chart" id="charts-item-wrap">
                        <canvas ref={canvasRef} width={250} height={100}/>
                    </div>
                </div>
                <div className="chart-side__footer mt-15">
                    <div className="c-grayblue fs-13 mb-5" style={{marginBottom: '.3rem'}}> Recommended transaction fee </div>
                    <span className="c-txt-main fs-13 satoshis"><span>36</span> satoshi per byte </span>
                </div>
                </a><br />
                <a href={`https://blockchair.com/${chain.toLowerCase()}/charts`} className="inline-link va-mid c-txt-main h6m link-litecoin">
                <img src="https://loutre.blockchair.io/assets/images/pages/charts/chart-line--orange.png" alt="Small chart icon" width="18" height="18" className="va-mid mr-5" />
                Other {chain?.charAt(0).toUpperCase() + chain?.slice(1)} charts
                <svg className="va-mid" id="arrow-right" width="14" height="13" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM1 9H16V7H1V9Z" fill="currentColor"></path>
                </svg>
                </a>
            </div>
            <div className="misc-box">
                <p>Alternative explorers</p>
                <div className="misc-box__items">
                    <a href="https://3pxl.com" className="bread-link">3pxl.com</a>
                    <span className="breadcrumbs__dot dot-grayblue-3px"></span>
                    <a href="https://btc.com" className="bread-link">btc.com</a>
                    <span className="breadcrumbs__dot dot-grayblue-3px"></span>
                    <a href="https://viabtc.com" className="bread-link">viabtc.com</a>
                </div>
                <p className="margin-top-5">For developers</p>
                <div className="misc-box__items">
                    <a href="https://blockchair.com" className="bread-link">Raw tx</a>
                </div>
            </div>
        </div>
      </div>
      <div className="newswrap">
        <News />
      </div>
    </div>
        </>
    );
}

export default Transaction;