import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import User from '../interfaces/user';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const useRegister = () => {
    const [error , setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { dispatch } = useAuthContext();

    const navigate = useNavigate();

    const signup = async (user: User) => {
        setIsLoading(true);
        setError('')
        try {
            let response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/auth/register', user, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            console.log(response);
            setIsLoading(false);
            dispatch({ type: 'LOGIN', payload: response.data });
            navigate('/admin');
        } catch (error: any) {
            if (error.response && error.response.data.message) {
                setError(error.response.data.message);
            }
            else {
                setError('Something went wrong. Please try again.');
            }
            setIsLoading(false);
        }
    }

    return { signup, error, isLoading };
}