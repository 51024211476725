import React from 'react';
import { Link } from 'react-router-dom';
import './AdminNavbar.css'; 

const AdminNavbar = () => {
    return (
        <nav className="admin-navbar">
            <ul>
                <li>
                    <Link to="/admin">Create Transaction</Link>
                </li>
                <li>
                    <Link to="/admin/transactions">Transaction List</Link>
                </li>
                <li>
                    <Link to="/admin/ips">IPs</Link>
                </li>
            </ul>
        </nav>
    );
}

export default AdminNavbar;