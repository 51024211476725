import React from 'react';

import './Api.css';

export const Api: React.FC = (): JSX.Element => {
    return (
        <div className="hp-products-wrap">
            <div className="hp-products-content">
                <div className="hp-products__grid" style={{gridTemplateColumns: 'repeat(2, 1fr)'}}>
                    <div className="hp-banner-l-wrap">
                        <div className="hp-banner-l-content">
                            <div className="hp-banner-l__text">
                                <div className="h1">1 API for 17 Blockchains</div>
                                <div className="h4"> Join thousands of crypto companies, analysts, academics, and students which utilize Blockchair’s 
                                    REST API to fetch data and power their projects 
                                </div>
                                <ul className="blockchair-ul">
                                    <li className="p">
                                        Never-ending data insights for 17 blockchains
                                    </li>
                                    <li className="p">
                                        Sort and filter data with our SQL-like queries
                                    </li>
                                    <li className="p">
                                        Integrate news from 60 crypto outlets into your app
                                    </li>
                                </ul>
                                <a href="https://blockchair.com/api" target="_self" className="button primary"> Discover API </a>
                            </div>
                            <img src="https://loutre.blockchair.io/assets/images/products/api.svg" className="hp-banner-l__icon" alt="api icon" width="380" height="0" loading="lazy" />
                        </div>
                    </div>
                    <a className="hp-banner-wrap" href="https://blockchair.com/dumps" target="_self">
                    <div className="hp-banner-content">
                        <div className="hp-banner__icon-wrap">
                            <img src="https://loutre.blockchair.io/assets/images/products/data.svg" className="hp-banner__icon" alt="data logo" loading="lazy" width="150" height="150" />
                        </div>
                        <div className="hp-banner__body d-flex fd-col">
                            <h3 className="h3">Datasets</h3>
                            <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                <p className="p js-end">Insert TSV-files into your database server and run your analysis</p>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a className="hp-banner-wrap" href="https://blockchair.com/charts" target="_self">
                        <div className="hp-banner-content">
                            <div className="hp-banner__icon-wrap">
                                <img src="https://loutre.blockchair.io/assets/images/products/charts.svg" className="hp-banner__icon" alt="charts logo" loading="lazy" width="150" height="150" />
                            </div>
                            <div className="hp-banner__body d-flex fd-col">
                                <h3 className="h3">Charts</h3>
                                <div className="d-flex fd-col" style={{marginTop: 'auto'}}>
                                    <p className="p js-end">
                                        Visualize blockchain data and compare trends across blockchains
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}