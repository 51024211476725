import React from 'react';

import './Explore.css';
import { useGetStats } from '../../hooks/useGetStats';

const getTimeDifference = (dateString: Date) => {
    const pastTime = new Date(dateString);
    if (isNaN(pastTime.getTime())) {
        return 'Invalid date';
    }
    const currentTime = new Date();
    const diffInSeconds = Math.floor((currentTime.getTime() - pastTime.getTime()) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 60 * 60) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 60 * 60 * 24) return `${Math.floor(diffInSeconds / (60 * 60))} hours ago`;

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    return `${minutes} minutes ago`;
};

export const Explore: React.FC = (): JSX.Element => {

    const getSmallCoinData = (coinName: string, stats: any) => {
        if (!stats || !stats.data || !stats.data[coinName.toLowerCase()]) {
            return { price: 'N/A', percentage: 'N/A' };
        }
    
        const coinData = stats.data[coinName.toLowerCase()];
        const price = `$${coinData.data.market_price_usd.toFixed(2)}`;
        const percentage = coinData.data.market_price_usd_change_24h_percentage.toFixed(2);
    
        return { price, percentage };
    };

    const { stats, getStats } = useGetStats();

    React.useEffect(() => {
        getStats(); // Fetch stats once on component mount
        console.log(stats);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // The linting rule is disabled for this line because we only want to run this effect once    

    const small_coins = [
        { name: "Dogecoin", key: 'dogecoin', price: "$0.30", percentage: "5", image: "dogecoin.svg" },
        { name: "Bitcoin Cash", key: 'bitcoin-cash', price: "$300.22", percentage: "-3", image: "bitcoin-cash.svg" },
        { name: "Stellar", key: 'stellar', price: "$0.20", percentage: "2", image: "stellar.svg" },
        { name: "Monero", key: 'monero', price: "$200.00", percentage: "-1.5", image: "monero.svg" },
        { name: "Kusama", key: 'kusama', price: "$100.50", percentage: "4", image: "kusama.svg" },
        { name: "eCash", key: 'ecash', price: "$0.0021", percentage: "3", image: "ecash.svg" },
        { name: "Zcash", key: 'zcash', price: "$110.30", percentage: "-2", image: "zcash.svg" },
        { name: "Dash", key: 'dash', price: "$150.80", percentage: "-1", image: "dash.svg" },
        // { name: "Mixin", key: 'mixin', price: "$250.00", percentage: "0.5", image: "mixin.svg" },
        { name: "Groestlcoin", key: 'groestlcoin', price: "$0.60", percentage: "1", image: "groestlcoin.svg" }
    ];

    const updatedSmallCoins = small_coins.map(coin => {
        const { price, percentage } = getSmallCoinData(coin.key, stats);
        return { ...coin, price, percentage };
    });

    const getCoinData = (coinKey: string, stats: any) => {

        // new date 5 min ago
        const fiveMinAgo = new Date();
        fiveMinAgo.setMinutes(fiveMinAgo.getMinutes() - 5);
        
        // new date 30 seconds ago
        const thirtySecAgo = new Date();
        thirtySecAgo.setSeconds(thirtySecAgo.getSeconds() - 30);

        if (coinKey === 'opbnb') {
            return {
                price: Number(234.65),
                percentage: `${1.00}%`,
                stats: {
                    Blocks: Number(9078208),
                    NewEvents24h: 6547016,
                    LatestBlock: getTimeDifference(fiveMinAgo),
                    EventsPerSecond: 75.78,
                    // TransactionsPerSecond: (coinData.mempool_tps?.toFixed(2)),
                }
            };
        }

        if (coinKey === 'bnb') {
            return {
                price: Number(234.65),
                percentage: `${1.00}%`,
                stats: {
                    Blocks: Number(33773519),
                    NewEvents24h: 25646388,
                    LatestBlock: getTimeDifference(thirtySecAgo),
                    EventsPerSecond: 295.64,
                    // TransactionsPerSecond: (coinData.mempool_tps?.toFixed(2)),
                }
            };
        }


        if (!stats || !stats.data || !stats.data[coinKey]) {
            return {
                price: 'N/A',
                percentage: 'N/A',
                blocks: 'N/A',
                transactions: 'N/A',
                latestBlock: 'N/A',
                transactionsPerSecond: 'N/A',
            };
        }
    
        const coinData = stats.data[coinKey].data;
        if (coinKey === 'ripple') {
            return {
                price: Number(coinData.market_price_usd.toFixed(2)),
                percentage: `${coinData.market_price_usd_change_24h_percentage.toFixed(2)}%`,
                stats: {
                    Ledgers: coinData.ledgers,
                    NewTransactions24h: coinData.transactions_24h,
                    LatestLedger: getTimeDifference(coinData.best_ledger_time),
                    EventsPerSecond: coinData.mempool_tps?.toFixed(2),
                }
            };
        }
        if (coinKey === 'polkadot') {
            return {
                price: Number(coinData.market_price_usd.toFixed(2)),
                percentage: `${coinData.market_price_usd_change_24h_percentage.toFixed(2)}%`,
                stats: {
                    Blocks: coinData.blocks,
                    Extrinsics: coinData.extrinsics,
                    LatestBlock: getTimeDifference(coinData.best_block_time),
                    ExtrinsicsPerSecond: (coinData.extrinsics_24h / (60 * 60 * 24)).toFixed(2),
                }
            };
        }

        if (coinKey === 'bnb') {
            return {
                price: Number(234.65),
                percentage: `${coinData.market_price_usd_change_24h_percentage.toFixed(2)}%`,
                stats: {
                    Blocks: coinData.blocks,
                    Extrinsics: coinData.extrinsics,
                    LatestBlock: getTimeDifference(coinData.best_block_time),
                    ExtrinsicsPerSecond: (coinData.extrinsics_24h / (60 * 60 * 24)).toFixed(2),
                }
            };
        }
        return {
            price: Number(coinData.market_price_usd.toFixed(2)),
            percentage: `${coinData.market_price_usd_change_24h_percentage.toFixed(2)}%`,
            stats: {
                Blocks: Number(coinData.blocks),
                Transactions: coinData.transactions,
                LatestBlock: getTimeDifference(coinData.best_block_time),
                TransactionsPerSecond: (coinData.transactions_24h / (60 * 60 * 24)).toFixed(2),
                // TransactionsPerSecond: (coinData.mempool_tps?.toFixed(2)),
            }
        };
    };

    const coins = [
        {
            name: "Bitcoin",
            price: "$34,980.34",
            image: "bitcoin.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/bitcoin"
        },
        {
            name: "Ethereum",
            price: "$2,980.34",
            image: "ethereum.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/ethereum"
        },
        {
            name: "BNB",
            price: "$34,980.34",
            image: "bnb.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/bnb"
        },
        {
            name: "opBNB",
            price: "$34,980.34",
            image: "opbnb.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/opbnb"
        },
        {
            name: "Litecoin",
            price: "$34,980.34",
            image: "litecoin.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/litecoin"
        },
        {
            name: "Cardano",
            price: "$34,980.34",
            image: "cardano.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/cardano"
        },
        {
            name: "Ripple",
            price: "$34,980.34",
            image: "ripple.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/ripple"
        },
        {
            name: "Polkadot",
            price: "$34,980.34",
            image: "polkadot.svg",
            stats: {
                Blocks: "815,934",
                Transactions: "916,341,234",
                LatestBlock: "5 minutes ago",
                TransactionsPerSecond: "4.52"
            },
            link: "https://blockchair.com/polkadot"
        }
    ];

    const updatedCoins = coins.map(coin => {
        const coinDetails = getCoinData(coin.name.toLowerCase(), stats);
        return { ...coin, ...coinDetails };
    });

    return (
        <div className="hp-explore-wrap">
            <div className="container-1280">
                <h1 className="title">Explore Blockchains</h1>
                    <div className="explore-card-wrapper">
                        <div className="hp-explore__cards-content">
                            {
                                updatedCoins.map((coin, index) => (
                                    <a key={index} className="explore-card-wrap" href={coin.link}>
                                        <div className="explore-card-content">
                                            <div className="explore-card-head">
                                                <img src={`https://loutre.blockchair.io/assets/svg/chains/${coin.image}?_nocache=2`} className="explore-card__header__logo" alt={`${coin.name} logo`} loading="lazy" />
                                                <div className="explore-card-head__title">
                                                    <h5 className="h5">{coin.name}</h5>
                                                    <div className="d-flex">
                                                    <p className="explore-card-price">${coin.price && coin.price.toLocaleString()}</p>
                                                    <p className="price-change ai-center">
                                                        {parseFloat(coin.percentage) >= 0 ?
                                                            <svg className="price-change__arrow--up change__arrow price-change__arrow--up" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#00CD5E"></path>
                                                            </svg>
                                                            :
                                                            <svg className="price-change__arrow--down change__arrow price-change__arrow--down" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#FF2626"></path></svg>
                                                        }
                                                        <div className="price-change__value d-flex" style={{color: parseFloat(coin.percentage) >= 0 ? 'rgb(0, 205, 94)' : 'rgb(255, 38, 38)'}}>
                                                            <span>{coin.percentage}</span>
                                                        </div>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="explore-card-body">
                                                {Object.entries(coin.stats).map(([key, value]) => (
                                                    <div className="explore-card-info-row" key={key}>
                                                        <div className="explore-card-info-row__title">{key.replace(/([A-Z])/g, ' $1').trim()}</div>
                                                        <div className="explore-card-info-row__value">{value && value.toLocaleString()}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </a>
                                ))
                            }
                           {
                                updatedSmallCoins.map((coin, index) => {
                                    return (
                                        <a className="explore-card-sm-wrap" href={`https://blockchair.com/${coin.name.toLowerCase()}`}>
                                            <div className="explore-card-content">
                                                <div className="explore-card-head">
                                                    <img src={`https://loutre.blockchair.io/assets/svg/chains/${coin.image}`} className="explore-card-sm__logo" alt={`${coin.name} logo`} loading="lazy" />
                                                    <div className="explore-card-head__title">
                                                        <h5 className="h5">{coin.name}</h5>
                                                        <div className="price-increase">
                                                            <p className="explore-card-price">{coin.price}</p>
                                                            <p className="price-change ai-center">
                                                                {parseFloat(coin.percentage) >= 0 ?
                                                                    <svg className="price-change__arrow--up change__arrow price-change__arrow--up" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#00CD5E"></path>
                                                                    </svg>
                                                                    :
                                                                    <svg className="price-change__arrow--down change__arrow price-change__arrow--down" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#FF2626"></path></svg>
                                                                }
                                                                <div className="price-change__value d-flex" style={{color: parseFloat(coin.percentage) >= 0 ? 'rgb(0, 205, 94)' : 'rgb(255, 38, 38)'}}>
                                                                    <span>{coin.percentage}</span>
                                                                    <span>%</span>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}