import React from "react";

import './Navbar.css';

export const Navbar: React.FC = (): JSX.Element => {
    return (
        <header className="hp-header__header">
            <img src="/assets/img/logo-white.svg" className="hp-header__header__logo"
                  id="homepage-header-logo" alt="Blockchair logo" width="200" height="0"/>
            <nav className="navbar-menu-wrap p-relative h6m">
                <input type="checkbox" id="c_more" />
                <input type="radio" name="nav-radio" id="r_explorers" />
                <div className="navbar-menu__item-wrap navbar-menu__item-wrap--text navbar-menu__item-wrap--explorers">
                    <a className="navbar-menu__item" href="/"> Explorers </a>
                    <div className="navbar-menu__item__content-wrap">
                        <div className="navbar-explorers-wrap">
                            <div className="navbar-explorers-content-wrap">
                                <span className="fs-13 c-txt-quiet-gray fw-400">Blockchains</span>
                                <span className="fs-13 c-txt-quiet-gray fw-400">Stablecoins</span>
                                <span className="fs-13 c-txt-quiet-gray fw-400">Cross-chains explorers</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbar-menu__item-wrap navbar-menu__item-wrap--text navbar-menu__item-wrap--features">
                    <a className="navbar-menu__item" href="/"> Features </a>
                </div>
                <div className="navbar-menu__item-wrap navbar-menu__item-wrap--settings" >
                    <div className="navbar-menu__item">
                        <p className="ws-nw fs-15 font-mono mr-10 d-iblock language" style={{paddingTop: '1px'}}> ENG<span style={{color: '#333', padding: '0px 2px'}}>•</span>USD</p>
                        <img  alt="x" src="https://loutre.blockchair.io/assets/svg/icon-settings.svg" className="navbar-menu__item__settings-icon" />
                    </div>
                </div>
            </nav>
            <div className="navbar-menu__item-wrap navbar-menu__item-wrap--portfolio">
                <div className="navbar-portfolio">
                    <div className="navbar-portfolio__nav-wrap">
                        <a href="https://blockchair.com/markets" target="_BLANK" rel="noreferrer" className="d-flex ai-center navbar-portfolio__nav-content">
                            <img src="https://loutre.blockchair.io/assets/images/products/portfolio--nav.svg" alt="Portfolio icon" width="22" />
                        </a>
                    </div>    
                </div>
            </div>
        </header>
    )
}