import { useState } from 'react';
import axios from 'axios';

export const useCreateTransaction = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const createTransaction = async (transactionData: any) => {
        setIsLoading(true);
        setError('');
        setIsSuccess(false);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/transaction`, transactionData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setIsLoading(false);
            setIsSuccess(true);
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong while creating the transaction');
            }
            setIsLoading(false);
        }
    };

    return { createTransaction, error, isLoading, isSuccess };
};