import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const useLogin = () => {
    const [error , setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { dispatch } = useAuthContext();

    const navigate = useNavigate();

    const handleLogin = async (email: string, password: string) => {
        setIsLoading(true);
        setError('')
        try {
            let response = await axios.post(process.env.REACT_APP_API_URL + '/api/v1/auth/login', { email, password }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setIsLoading(false);
            dispatch({ type: 'LOGIN', payload: response.data });
            navigate('/admin');
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong');
            }
            setIsLoading(false);
        }
    }

    return { handleLogin, error, isLoading };
}
