import React from "react";

import './Home.css'; 
import { Landing } from "./landing/Landing";
import { Explore } from "../explore/Explore";
import { Layer2 } from "./layer2/Layer2";
import { Info } from "./info/Info";
import { Api } from "./api/Api";
import { News } from "./news/News";
import { Services } from "./services/Services";
import { Privacy } from "./privacy/Privacy";

export const Home: React.FC = (): JSX.Element => {
    return (
        <div className="home">
            <Landing />
            <Explore />
            <Layer2 />
            <Info />
            <Api />
            <News />
            <Services />
            <Privacy />
        </div>
    )
}