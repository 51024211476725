import { useState } from 'react';
import axios from 'axios';

export const useGetTransaction = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transaction, setTransaction] = useState<any>(null);

    const getTransaction = async (chain: string, transactionHash: string) => {
        // setIsLoading(true);
        setError('');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/blockchair/transaction?chain=${chain}&hash=${transactionHash}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setIsLoading(false);
            // setTransaction(response.data);
            return response.data;
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong');
            }
            setIsLoading(false);
        }
    }

    return { transaction, getTransaction, error, isLoading, setTransaction };
}