import React from 'react';

import './News.css';

export const News: React.FC = (): JSX.Element => {
    return (
        <div className="hp-news-wrap">
            <div className="hp-news-content">
                <h3 className="h3"> Blockchair Crypto News </h3>
                <div className="hp-news__news">
                    <a href="https://blockchair.com/news/earlystage-vc-explains-how-private-valuations-are-reacting-to-cryptos-latest-rally--101f58a2e7" className="news-card-wrap">
                        <div className="news-card-content">
                            <h5 className="h5 news-card__header"> Early-stage VC explains how private valuations are reacting to crypto’s latest rally </h5>
                            <div className="p news-card__body"> Delta Blockchain Fund CEO discusses the impact of the recent crypto surge on the early-stage venture... </div>
                            <div className="txt-basic news-card__footer c-txt-quiet-gray"><div>
                            <span className="value-wrapper d-iflex ai-center">
                                <span className="">
                                    <span className="wb-ba">8 minutes ago</span>
                                </span>
                                <span className="ai-center d-none">
                                    {/* <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'rotate(180deg)'}}>
                                        <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#ff2626"></path>
                                    </svg>
                                    <span className="ml-5 font-h medium fs-14 ls-5 lh-100" style={{color: '#ff2626'}}>0% </span> */}
                                </span>
                            </span>
                        </div>
                        <div> by theblock.co </div>
                    </div>
                </div>
            </a>
            <a href="https://blockchair.com/news/sec-seeks-summary-judgment-against-terraform-labs-and-do-kwon--acd8a9bd7b" className="news-card-wrap">
                <div className="news-card-content">
                    <h5 className="h5 news-card__header"> SEC Seeks Summary Judgment Against Terraform Labs and Do Kwon </h5>
                    <div className="p news-card__body"> The post SEC Seeks Summary Judgment Against Terraform Labs and Do Kwon appeared first on Coinpedia F... </div>
                    <div className="txt-basic news-card__footer c-txt-quiet-gray"><div>
                    <span className="value-wrapper d-iflex ai-center">
                        <span className="">
                            <span className="wb-ba">9 minutes ago</span>
                        </span>
                        <span className="ai-center d-none">
                            {/* <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'rotate(180deg)'}}>
                                <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#ff2626"></path>
                            </svg>
                            <span className="ml-5 font-h medium fs-14 ls-5 lh-100" style={{color: '#ff2626'}}>0% </span> */}
                        </span>
                    </span>
                </div>
                <div> by coinpedia.org </div>
                </div>
            </div>
            </a>
            <a href="https://blockchair.com/news/uaes-latest-guidance-to-address-unauthorized-digital-asset-usage--3214d69c74" className="news-card-wrap">
                <div className="news-card-content">
                    <h5 className="h5 news-card__header"> UAE’s Latest Guidance To Address Unauthorized Digital Asset Usage </h5>
                    <div className="p news-card__body"> The United Arab Emirates (UAE) has revealed its latest guidance to combat the use of unauthorized di... </div>
                    <div className="txt-basic news-card__footer c-txt-quiet-gray"><div>
                        <span className="value-wrapper d-iflex ai-center">
                            <span className="">
                                <span className="wb-ba">11 minutes ago</span>
                            </span>
                            <span className="ai-center d-none">
                                {/* <svg className="ml-10" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: 'rotate(180deg)'}}>
                                    <path d="M1.1837 4.99987L4.71924 1.46433L8.25477 4.99987" stroke="#ff2626"></path>
                                </svg>
                                <span className="ml-5 font-h medium fs-14 ls-5 lh-100" style={{color: '#ff2626'}}>0% </span> */}
                                </span>
                            </span>
                        </div>
                        <div> by bitcoinist.com </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="hp-news__footer">
            <a href="https://blockchair.com/news" className="h4 hp-news__footer__more"> More news <span className="hp-news__footer__more__icon ml-5 rtl-flip">
                <svg width="14" height="15" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 7.9C0.892487 7.9 0.4 8.39249 0.4 9C0.4 9.60751 0.892487 10.1 1.5 10.1V7.9ZM16.2778 9.77782C16.7074 9.34824 16.7074 8.65176 16.2778 8.22218L9.27746 1.22183C8.84788 0.792249 8.1514 0.792249 7.72183 1.22183C7.29225 1.6514 7.29225 2.34788 7.72183 2.77746L13.9444 9L7.72183 15.2225C7.29225 15.6521 7.29225 16.3486 7.72183 16.7782C8.1514 17.2078 8.84788 17.2078 9.27746 16.7782L16.2778 9.77782ZM1.5 10.1H15.5V7.9H1.5V10.1Z" fill="#2170FF"></path>
                </svg>
            </span>
            </a>
            <a className="h6m hp-news__footer__subscribe ws-nw" href="https://t.me/BlockchairNews" target="_blank" rel="noreferrer">
                <span className="hp-news__footer__subscribe__icon mr-5">
                    <svg viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={{height: '32px', width: '32px'}}><g clip-path="url(#clip0)">
                        <path d="M17 34.0635C26.3888 34.0635 34 26.4523 34 17.0635C34 7.67464 26.3888 0.0634766 17 0.0634766C7.61116 0.0634766 0 7.67464 0 17.0635C0 26.4523 7.61116 34.0635 17 34.0635Z" fill="#2195FB"></path>
                        <path d="M7.77904 16.6952L24.1699 10.3754C24.9306 10.1006 25.595 10.561 25.3485 11.7114L25.35 11.7099L22.5591 24.858C22.3523 25.7902 21.7984 26.0169 21.0235 25.5777L16.7735 22.4454L14.7235 24.4203C14.4969 24.6469 14.3056 24.8382 13.8665 24.8382L14.1682 20.5131L22.0449 13.3972C22.3877 13.0954 21.9684 12.9254 21.5165 13.2258L11.7825 19.3543L7.58638 18.0453C6.67546 17.7563 6.65563 17.1344 7.77904 16.6952Z" fill="white"></path>
                    </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="34" height="34" fill="white" transform="translate(0 0.0634766)"></rect>
                    </clipPath>
                </defs>
            </svg>
            </span> Subscibe to News on Telegram </a>
        </div>
    </div>
    </div>
    );
}