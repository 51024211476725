import React, { useEffect, useState } from "react";

import './Landing.css';
import { Navbar } from "../../../components/navbar/Navbar";
import { FaArrowRight } from "react-icons/fa";
import { useSearchTransaction } from "../../../hooks/useSearchTransaction";
import { useNavigate } from "react-router-dom";

export const Landing: React.FC = (): JSX.Element => {

    const [searchHash, setSearchHash] = useState('');
    const { searchTransaction, transactionData } = useSearchTransaction();
    const navigate = useNavigate();

    useEffect(() => {
        if (transactionData && transactionData.chain) {
            navigate(`/${transactionData.chain}/transaction/${searchHash}`);
        }
    }, [transactionData, navigate, searchHash]);

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await searchTransaction(searchHash);
    };
    
    return (
        <div className="landing">
            <div className="bg-header-wrap" style={{backgroundImage: `url('/assets/img/hp-header-cover.svg')`}}>
                <div className="container-1280">
                    <Navbar />
                    <div className="hp-header__title-row">
                        <div style={{color: '#fff'}}>
                            <h1 className="h1"> Blockchain explorer,
                            <br />analytics and web services </h1>
                            <div className="p hp-header__title-row__caption"> 
                                Explore data stored on 17 blockchains 
                            </div>
                        </div>
                        <div className="hp-header__promo">
                            <div className="banner-promo-wrap extension">
                                <div className="f-vert-dense">
                                    <div className="f-hor-def">
                                        <img src="https://loutre.blockchair.io/assets/images/products/extension.png" className="banner-promo__icon" alt="Extension logo" loading="lazy" />
                                        <div className="h7 as-center"> 
                                            Blockchair search,<br />now in your browser! 
                                        </div>
                                    </div>
                                    <div className="w-100 chrome-wrap">
                                    <a className="ext-button bgc-bright d-iflex jc-between ac-center ai-center ta-left txt-basic hover-pointer sm-hide c-txt-main active" href="https://chrome.google.com/webstore/detail/blockchair/fhhkkooikehnkaodebbfnkinedlllcfk" target="_blank" rel="noopener noreferrer">
                                        <div className="d-iflex ai-center ws-nw chrome-text">
                                            <img className="mr-5 va-mid" src="https://loutre.blockchair.io/assets/images/banners/extension/chrome.svg" alt="chrome icon" width="18" height="18" />Chrome Extension 
                                        </div>
                                        <svg className="rtl-flip as-center ml-5" width="18" height="18" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.38112 3.78373L8.78112 3.78373C9.11249 3.78373 9.38112 4.05236 9.38112 4.38373V9.78373C9.38112 10.1151 9.11249 10.3837 8.78112 10.3837C8.44975 10.3837 8.18112 10.1151 8.18112 9.78373V5.83226L3.54853 10.4648L2.7 9.61632L7.33259 4.98373L3.38112 4.98373C3.04975 4.98373 2.78112 4.7151 2.78112 4.38373C2.78112 4.05236 3.04975 3.78373 3.38112 3.78373Z" fill="#2170ff">
                                            </path>
                                        </svg>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSearch} className="w-100 d-flex ai-center br-15 bw-2 bgc-accent bs-solid bc-transparent" id="searchbar--hp">
                        <label className="searchbar__label">
                            {/* <input className="searchbar__input w-100 d-flex ai-center as-stretch font-p regular fs-17 lh-160 c-txt-main" placeholder="Search for transactions, addresses, blocks and embedded text data..." name="q" id="searchbar__input" pattern=".+" /> */}
                            <input 
                                className="searchbar__input w-100 d-flex ai-center as-stretch font-p regular fs-17 lh-160 c-txt-main" 
                                placeholder="Search for transactions, addresses, blocks and embedded text data..." 
                                name="q" 
                                id="searchbar__input" 
                                pattern=".+" 
                                value={searchHash}
                                onChange={(e) => setSearchHash(e.target.value)}
                            />
                            <svg className="searchbar__glass" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.51569 16.2567L5.72376 12.3125C6.96043 13.3266 8.51641 13.8823 10.1363 13.8823C13.9209 13.8823 17 10.8806 17 7.19113C17 3.5017 13.9209 0.5 10.1363 0.5C6.35165 0.5 3.27251 3.5017 3.27251 7.19113C3.27251 8.57619 3.70105 9.89609 4.51455 11.0246L0.274549 14.9988C0.0975831 15.1649 -6.5079e-07 15.3883 -6.61256e-07 15.6278C-6.71162e-07 15.8544 0.0886319 16.0694 0.24978 16.2326C0.592073 16.5794 1.15967 16.5904 1.51569 16.2567ZM15.2095 7.19113C15.2095 9.9182 12.9337 12.1367 10.1363 12.1367C7.33883 12.1367 5.06305 9.9182 5.06305 7.19113C5.06305 4.46406 7.33883 2.24551 10.1363 2.24551C12.9337 2.24551 15.2095 4.46406 15.2095 7.19113Z" fill="url(#hpsbb)"></path>
                                <defs><linearGradient id="hpsbb" x1="17" y1="0.5" x2="11.3195" y2="19.827" gradientUnits="userSpaceOnUse"><stop stop-color="#2170ff"></stop><stop offset="1" stop-color="#6934ff"></stop></linearGradient></defs>
                            </svg>
                            <div className="searchbar__qr-wrap data-v-tooltip v-tooltip-placement-top-right" data-v-tooltip="Scan the QR code of an address or a transaction to search.">
                                <img className="searchbar__qr" src="https://loutre.blockchair.io/assets/svg/camera.svg"  alt="x"/>
                            </div>
                        </label>
                        <button type="submit" className="searchbar__arrow d-flex ai-center as-stretch" aria-label="Search">
                        <div className="arrow-wrapper">
                            <FaArrowRight color="#fff" size={20} style={{ strokeWidth: '2' }}/>
                        </div>
                        </button>
                    </form>
                    <div className="h7 hp-header__search-examples">
                        <span> Search examples: </span><span className="hp-header__search-examples__item __adress" data-search-example="address"><span className="hp-header__search-examples__icon">
                            <img  alt="x" src="https://loutre.blockchair.io/assets/svg/homepage-examples-address.svg" />
                        </span>
                        <span className="hp-header__search-examples__title">&nbsp;Address</span>
                        </span>
                        <span className="hp-header__search-examples__item __block" data-search-example="block">
                            <span className="hp-header__search-examples__icon"><img  alt="x" src="https://loutre.blockchair.io/assets/svg/homepage-examples-block.svg" />
                            </span>
                            <span className="hp-header__search-examples__title">&nbsp;Block</span>
                        </span>
                        <span className="hp-header__search-examples__item __transaction" data-search-example="transaction">
                            <span className="hp-header__search-examples__icon">
                                <img  alt="x" src="https://loutre.blockchair.io/assets/svg/homepage-examples-transaction.svg" />
                                
                            </span>
                            <span className="hp-header__search-examples__title">&nbsp;Transaction</span>
                        </span>
                        <span className="hp-header__search-examples__item __embedded" data-search-example="embedded">
                            <span className="hp-header__search-examples__icon"><img alt="x"  src="https://loutre.blockchair.io/assets/svg/homepage-examples-embeded.svg" /></span>
                            <span className="hp-header__search-examples__title">&nbsp;Embedded text data</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}  