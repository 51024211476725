import React, { useEffect, useState } from 'react';
import { useGetIPs } from '../../../hooks/useGetIps';
import './Ips.css';
import AdminNavbar from '../admin-navbar/AdminNavbar';

const Ips = () => {
    const { ips, getIPs, error, isLoading } = useGetIPs();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10; // Number of records per page

    useEffect(() => {
        getIPs(currentPage, limit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handlePageChange = (newPage: any) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <AdminNavbar />
            <div className="ips-list"> {/* Apply the ips-list class here */}
                <h2>IP Address Log</h2>
                {isLoading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>IP Address</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ips.data && ips.data.map((ip: any, index: number) => (
                            <tr key={index}>
                                <td>{ip.id}</td>
                                <td>{ip.ip_address}</td>
                                <td>{new Date(ip.timestamp).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {ips.totalPages && (
                    <div className="pagination"> {/* Apply the pagination class here */}
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span> Page {currentPage} of {ips.totalPages} </span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === ips.totalPages}>
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Ips;