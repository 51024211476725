import React from 'react';

import './Layer2.css';

export const Layer2: React.FC = () => {
    return (
        <>
            <div className="layer2wrap">
                <div className="container-1280">
                    <div className="line-wrap">
                        <div className="h6m ws-nw mr-10">
                            Layer 2 explorers
                        </div>
                        <div className="line">
                        </div>
                    </div>
                    <div className="token-wrapper">
                        <div className="erc20 tokens">
                            <h2 className="h2 hp-explore__eth-header__title">
                                <img src="https://loutre.blockchair.io/assets/images/banners/logo_erc20_dashboard-banner.png" width="100" height="100" loading="lazy" alt="ERC-20 banner" className="display-block mlr-auto mb-5" />
                                <br />
                                ERC-20 tokens
                            </h2>
                            <div className="hp-layer2__tokens-wrap hp-layer2-wrap">
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item"><img className="hp-layer2__tokens__item__logo" src="https://loutre.blockchair.io/assets/images/layer2_icons/png/128/binance-coin-bnb.png" alt="ERC-20 token logo" />
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> BNB </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> BNB </span>
                                            <span className="price-wrap">
                                                $250.09
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item">
                                <img className="hp-layer2__tokens__item__logo" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/steth-steth.svg" alt="ERC-20 token logo" />
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Lido Staked Ether </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet">STETH </span>
                                            <span className="price-wrap">
                                                $1,927.29
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item">
                                    <img className="hp-layer2__tokens__item__logo" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/chainlink-link.svg" alt="ERC-20 token logo" />
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Chainlink </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet">LINK </span>
                                            <span className="price-wrap">
                                                $15.61
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item">
                                    <img className="hp-layer2__tokens__item__logo" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/polygon-matic.svg" alt="ERC-20 token logo" />
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Polygon </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> MATIC </span>
                                            <span className="price-wrap">
                                                $0.82
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item">
                                    <div className="hp-layer2__tokens__item__logo">
                                    <svg width="22" height="25" viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg"><path d="M8.02577 7.82831H6.42766L11.0979 0.563721L15.7681 7.82831H14.17H8.02577Z" fill="#86cce5"></path><path d="M13.1647 19.8448H14.8901L11.0983 24.5637L7.30655 19.8448H9.03195H13.1647Z" fill="#86cce5"></path><path d="M0.669825 12.1087H1.66295H2.38894H2.71107L4.45533 13.2715V10.8909H17.7431V13.2715L19.4873 12.1087H19.8095H20.5355H21.5286L21.5317 12.1118L17.7431 16.826V16.9942H4.45533V16.826L0.666681 12.1118L0.669825 12.1087Z" fill="#86cce5"></path></svg>
                                    </div>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Tonecoin </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> TON </span>
                                            <span className="price-wrap">
                                                $2.52
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-20/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52" className="hp-layer2__tokens__item">
                                    <img className="hp-layer2__tokens__item__logo" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/multi-collateral-dai-dai.svg" alt="ERC-20 token logo" />
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Dai </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> DAI </span>
                                            <span className="price-wrap">
                                                $1.00
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <a href="https://blockchair.com/ethereum/erc-20" className="token-link">
                                Explore ERC-20 tokens
                                <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" loading="lazy" alt="Right arrow" className="token-arrow"/>
                            </a>
                        </div>
                        <div className="erc721 tokens">
                            <h2 className="h2 hp-explore__eth-header__title">
                            <img src="https://loutre.blockchair.io/assets/images/banners/logo_erc721_dashboard-banner.png" width="100" height="100" loading="lazy" alt="ERC-721 banner" className="display-block mlr-auto mb-5" />
                            <br />
                            ERC-20 tokens
                            </h2>
                            <div className="hp-layer2__tokens-wrap hp-layer2-wrap">
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#abde01"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#abde01"></path><path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#abde01"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#abde01"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> ENS </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> ENS </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#ec5e36"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#ec5e36"></path>
                                            <path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#ec5e36"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#ec5e36"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> BRUCE </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> BRC </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#3599be"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#3599be"></path>
                                            <path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#3599be"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#3599be"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> ample </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> B-BRN </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#c284bc"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#c284bc"></path>
                                            <path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#c284bc"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#c284bc"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> eggs </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> EGGS </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#abde01"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#abde01"></path><path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#abde01"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#abde01"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Memes & Myths </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> CARELLA </span>
                                        </div>
                                    </div>
                                </a>
                                <a href="/ethereum/erc-721/token/0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" className="hp-layer2__tokens__item">
                                    <span className="hp-layer2__tokens__item__logo">
                                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M35.3547 19.52C35.3547 28.0003 28.4801 34.8749 19.9999 34.8749C11.6856 34.8749 4.91431 28.2663 4.65286 20.0163C4.64764 19.8516 4.64501 19.6861 4.64501 19.52C4.64501 11.0398 11.5196 4.16518 19.9999 4.16518C28.4801 4.16518 35.3547 11.0398 35.3547 19.52ZM33.9892 29.1819C30.9212 33.6156 25.7997 36.52 19.9999 36.52C16.1709 36.52 12.6376 35.2542 9.79563 33.1181C12.4929 35.9492 16.3108 37.7167 20.5482 37.7167C26.5095 37.7167 31.6406 34.2184 33.9892 29.1819ZM36.9996 19.62H36.9998L36.9998 21.2651L36.9998 23.0023C36.9998 32.0375 29.6342 39.3619 20.5482 39.3619C12.6373 39.3619 6.03052 33.8095 4.45394 26.4098C3.58914 24.4614 3.07988 22.3202 3.00853 20.0684C3.00275 19.8863 2.99985 19.7035 2.99985 19.52C2.99985 10.1312 10.611 2.52002 19.9999 2.52002C29.3887 2.52002 36.9999 10.1312 36.9999 19.52C36.9999 19.5534 36.9998 19.5867 36.9996 19.62Z" fill="#abde01"></path>
                                            <path d="M14.7066 17.0443L17.0186 8.39653L23.3448 14.7297L19.6022 18.0384L14.7066 17.0443Z" fill="#abde01"></path><path d="M24.4335 23.7692L22.9804 30.6438L18.2847 25.4168L20.9748 23.1588L24.4335 23.7692Z" fill="#abde01"></path>
                                            <path d="M10.7125 20.8652L12.6003 20.3594L13.8915 21.2985L13.2337 18.8438L15.5192 18.2314L20.0347 19.6524L23.2377 16.1633L25.5224 15.5511L26.1802 18.0058L26.8288 16.5469L28.7166 16.0411L28.7203 16.0435L25.8909 23.4922L24.8633 22.2303L20.6198 21.8301L17.1448 24.2985L16.8859 25.9051L10.7105 20.8692L10.7125 20.8652Z" fill="#abde01"></path>
                                        </svg>
                                    </span>
                                    <div className="hp-layer2__tokens__item__data">
                                        <div className="h6"> Uniswap V3 Positions NFT-V1 </div>
                                        <div className="txt-data">
                                            <span className="c-txt-quiet"> UNI-V3-POS </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <a href="https://blockchair.com/ethereum/erc-721" className="token-link">
                                Explore ERC-721 tokens (NFT)
                                <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" loading="lazy" alt="Right arrow" className="token-arrow"/>
                            </a>
                        </div>
                    </div>
                    <div className="hp-explore__layer2-explorers-wrap h5">
                        <a className="hp-explore__layer2-explorers__item" href="https://blockchair.com/bitcoin/omni">
                            <img  alt="x" src="https://loutre.blockchair.io/assets/images/banners/logo_omni-explorer.png" loading="lazy" className="hp-explore__layer2-explorers__img mlr-auto w-100 as-center" />
                            <div className="va-mid mlr-auto explore-layers">
                            <div className="wb-bw ta-center"> Omni layer for Bitcoin </div>
                            <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" className="display-block" alt="x"/></div>
                        </a>
                        <a className="hp-explore__layer2-explorers__item" href="https://blockchair.com/tether">
                            <img  alt="x" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/tether-usdt.svg" loading="lazy" className="hp-explore__layer2-explorers__img mlr-auto w-100 as-center" />
                            <div className="va-mid mlr-auto explore-layers">
                            <div className="wb-bw ta-center"> Tether explorer </div>
                            <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" className="display-block"  alt="x"/>
                            </div>
                        </a>
                        <a className="hp-explore__layer2-explorers__item" href="https://blockchair.com/usd-coin">
                            <img  alt="x" src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/usd-coin-usdc.svg" loading="lazy" className="hp-explore__layer2-explorers__img mlr-auto w-100 as-center" />
                            <div className="va-mid mlr-auto explore-layers">
                                <div className="wb-bw ta-center"> USD Coin explorer </div>
                                <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" className="display-block"  alt="x"/>    
                            </div>
                        </a>
                        <a className="hp-explore__layer2-explorers__item" href="https://blockchair.com/binance-usd">
                            <img src="https://loutre.blockchair.io/assets/images/layer2_icons/svg/binance-usd-busd.svg" loading="lazy" className="hp-explore__layer2-explorers__img mlr-auto w-100 as-center"  alt="x"/>
                            <div className="va-mid mlr-auto explore-layers">
                                <div className="wb-bw ta-center"> Binance USD explorer </div>
                                <img src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14" className="display-block"  alt="x"/>
                            </div>
                        </a>
                    </div>
                    <div className="hp-explore__banners">
                        <a href="https://blockchair.com/bitcoin/privacy-o-meter" className="hp-explore-banner-wrap">
                            <div className="hp-explore-banner-content">
                                <img  alt="x" src="https://loutre.blockchair.io/assets/images/banners/homepage/explore/pom--circled.png" className="hp-explore-banner__img as-center" />
                                <div className="as-center">
                                    <h3 className="h3"> Privacy-o-meter for Bitcoin 
                                    <img className="va-mid token-arrow" src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14"  alt="x"/>
                                    </h3>
                                    <p className="p mt-5 text-muted"> 
                                    Learn whether your transactions<br />are traceable and pose a risk to your privacy<br />on the Bitcoin network 
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a href="/extensions" className="hp-explore-banner-wrap">
                            <div className="hp-explore-banner-content">
                                <img  alt="x" src="https://loutre.blockchair.io/assets/images/banners/homepage/explore/extension--circled.png" className="hp-explore-banner__img as-center" />
                                <div className="as-center">
                                    <h3 className="h3"> Get Blockchair extension 
                                    <img className="va-mid token-arrow" src="https://loutre.blockchair.io/assets/svg/arrow-right.svg" width="14" height="14"  alt="x"/></h3>
                                    <p className="p mt-5 text-muted"> Blockchair brings the search engine for :count blockchains to your browser 
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}