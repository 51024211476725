import React from 'react';
import { useGetAllTransactions } from '../../../hooks/useGetAllTransactions';
import { useDeleteTransaction } from '../../../hooks/useDeleteTransaction';
import './TransactionList.css';
import { useNavigate } from 'react-router-dom';
import AdminNavbar from '../admin-navbar/AdminNavbar';

const TransactionList = () => {
    const { transactions, isLoading, error } = useGetAllTransactions();
    const { deleteTransaction, isSuccess } = useDeleteTransaction();
    const navigate = useNavigate();

    const handleDelete = async (transactionId: string) => {
        await deleteTransaction(transactionId);
    };

    if (isLoading) return <p>Loading transactions...</p>;
    if (error) return <p>Error loading transactions: {error}</p>;

    return (
        <>
            <AdminNavbar />
            <div className="transaction-list">
                <h2>Transactions</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Block ID</th>
                            <th>Chain</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction: any) => (
                            <tr key={transaction.transaction_id}>
                                <td>{transaction.transaction_id}</td>
                                <td>{transaction.block_id}</td>
                                <td>{transaction.chain}</td>
                                <td>
                                    <button className="edit" onClick={() => navigate('/admin/' + transaction.transaction_id)}>Edit</button>
                                </td>
                                <td>
                                    <button onClick={() => handleDelete(transaction.transaction_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isSuccess && <p>Transaction deleted successfully.</p>}
            </div>
        </>
    );
};

export default TransactionList;