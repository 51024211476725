import { useState } from 'react';
import axios from 'axios';

export const useUpdateTransaction = () => {
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const updateTransaction = async (transactionId: string, transactionData: any) => {
        setIsLoading(true);
        setError('');
        setIsSuccess(false);

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/admin/transaction/${transactionId}`, transactionData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setIsLoading(false);
            setIsSuccess(true);
        } catch (err: any) {
            if (err.response && err.response.data) {
                setError(err.response.data.message || 'Something went wrong while updating the transaction');
            } else {
                setError('An error occurred while updating the transaction');
            }
            setIsLoading(false);
        }
    };

    return { updateTransaction, error, isLoading, isSuccess };
};