import React from 'react';

import './TransactionNavbar.css';
import { FaArrowRight } from 'react-icons/fa';
import { useSearchTransaction } from '../../hooks/useSearchTransaction';
import { useNavigate } from 'react-router-dom';

export const TransactionNavbar: React.FC = (): JSX.Element => {

    const [searchHash, setSearchHash] = React.useState('');
    const { searchTransaction, transactionData } = useSearchTransaction();
    const navigate = useNavigate();

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await searchTransaction(searchHash);
    };

    React.useEffect(() => {
        if (transactionData && transactionData.chain) {
            navigate(`/${transactionData.chain}/transaction/${searchHash}`);
        }
    }, [transactionData, navigate, searchHash]);

    return (
        <nav className="navbar-wrap">
            <div className="container-1280">
                <div className=" nav-cont">
                    <a href="/" className="navbar-logo">
                        <img src="/assets/img/logo-white.svg"
                            id="homepage-header-logo" alt="Blockchair logo" height="30"/>
                    </a>
                    <form onSubmit={handleSearch} className="w-100 d-flex ai-center br-15 bw-2 bgc-accent bs-solid bc-transparent" id="searchbar--hp">
                        <label className="searchbar__label">
                            <input 
                                className="searchbar__input w-100 d-flex ai-center as-stretch font-p regular fs-17 lh-160 c-txt-main" 
                                placeholder="Search for transactions, addresses, blocks and embedded text data..." 
                                name="q" 
                                id="searchbar__input" 
                                pattern=".+" 
                                value={searchHash}
                                onChange={(e) => setSearchHash(e.target.value)}
                            />
                            <svg className="searchbar__glass" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.51569 16.2567L5.72376 12.3125C6.96043 13.3266 8.51641 13.8823 10.1363 13.8823C13.9209 13.8823 17 10.8806 17 7.19113C17 3.5017 13.9209 0.5 10.1363 0.5C6.35165 0.5 3.27251 3.5017 3.27251 7.19113C3.27251 8.57619 3.70105 9.89609 4.51455 11.0246L0.274549 14.9988C0.0975831 15.1649 -6.5079e-07 15.3883 -6.61256e-07 15.6278C-6.71162e-07 15.8544 0.0886319 16.0694 0.24978 16.2326C0.592073 16.5794 1.15967 16.5904 1.51569 16.2567ZM15.2095 7.19113C15.2095 9.9182 12.9337 12.1367 10.1363 12.1367C7.33883 12.1367 5.06305 9.9182 5.06305 7.19113C5.06305 4.46406 7.33883 2.24551 10.1363 2.24551C12.9337 2.24551 15.2095 4.46406 15.2095 7.19113Z" fill="url(#hpsbb)"></path>
                                <defs><linearGradient id="hpsbb" x1="17" y1="0.5" x2="11.3195" y2="19.827" gradientUnits="userSpaceOnUse"><stop stop-color="#2170ff"></stop><stop offset="1" stop-color="#6934ff"></stop></linearGradient></defs>
                            </svg>
                            <div className="searchbar__qr-wrap data-v-tooltip v-tooltip-placement-top-right" data-v-tooltip="Scan the QR code of an address or a transaction to search.">
                                <img className="searchbar__qr" src="https://loutre.blockchair.io/assets/svg/camera.svg"  alt="x"/>
                            </div>
                        </label>
                        <button type="submit" className="searchbar__arrow d-flex ai-center as-stretch" aria-label="Search">
                        <div className="arrow-wrapper">
                            <FaArrowRight color="#fff" size={20} style={{ strokeWidth: '2' }}/>
                        </div>
                        </button>
                    </form>
                    <nav className="navbar-menu-wrap p-relative">
                        <input type="checkbox" id="c_more" />
                        <input type="radio" name="nav-radio" id="r_explorers" />
                        <div className="navbar-menu__item-wrap navbar-menu__item-wrap--portfolio">
                            <div className="navbar-portfolio">
                                <div className="navbar-portfolio__nav-wrap">
                                    <a href="https://blockchair.com/markets" target="_BLANK" rel="noreferrer" className="d-flex ai-center navbar-portfolio__nav-content">
                                        <img src="https://loutre.blockchair.io/assets/images/products/portfolio--nav.svg" alt="Portfolio icon" width="22" />
                                    </a>
                                </div>    
                            </div>
                        </div>
                        <div className="navbar-menu__item-wrap navbar-menu__item-wrap--text navbar-menu__item-wrap--explorers">
                            <a className="navbar-menu__item" href="/"> Explorers </a>
                            <div className="navbar-menu__item__content-wrap">
                                <div className="navbar-explorers-wrap">
                                    <div className="navbar-explorers-content-wrap">
                                        <span className="fs-13 c-txt-quiet-gray fw-400">Blockchains</span>
                                        <span className="fs-13 c-txt-quiet-gray fw-400">Stablecoins</span>
                                        <span className="fs-13 c-txt-quiet-gray fw-400">Cross-chains explorers</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navbar-menu__item-wrap navbar-menu__item-wrap--text navbar-menu__item-wrap--features">
                            <a className="navbar-menu__item" href="/"> Features </a>
                        </div>
                        <div className="navbar-menu__item-wrap navbar-menu__item-wrap--settings" >
                            <div className="navbar-menu__item">
                                <p className="ws-nw fs-15 font-mono mr-10 d-iblock language" style={{paddingTop: '1px'}}> ENG<span style={{color: '#333', padding: '0px 2px'}}>•</span>USD</p>
                                <img  alt="x" src="https://loutre.blockchair.io/assets/svg/icon-settings.svg" className="navbar-menu__item__settings-icon" />
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </nav>
    )
}