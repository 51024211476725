import { createContext , useReducer, useEffect, useState } from 'react';
import { Loader } from '../components/loader/Loader';
import axios from 'axios';

export const AuthContext = createContext<any | null>(null);

export const authReducer = (state: any, action: any) => {
    switch(action.type) {
        case 'LOGIN':
            return {
                user: action.payload
            }
        case 'LOGOUT':
            return {
                user: null
            }
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(
        authReducer, {
            user: null
        }
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                let res = await axios.get(process.env.REACT_APP_API_URL + '/api/v1/auth/isLoggedIn', {
                    withCredentials: true
                });
                if (res.data) {
                    dispatch({
                        type: 'LOGIN',
                        payload: res.data
                    });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
            }
        })();
    }, []);

    if (loading)
        return (<Loader />)

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}