import { useState, useEffect } from 'react';
import axios from 'axios';

export const useGetAllTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/transaction`, {
                    withCredentials: true,
                });
                setTransactions(response.data);
                setIsLoading(false);
            } catch (err: any) {
                if (err.response && err.response.data) {
                    setError(err.response.data.message);
                } else {
                    setError('Error fetching transactions');
                }
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    return { transactions, error, isLoading };
};