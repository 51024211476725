import { useState } from 'react';

import axios from 'axios';

export const useGetStats = () => {
    const [error , setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<any>([]);

    const getStats = async () => {
        setIsLoading(true);
        setError('')
        try {
            let response = await axios.get(process.env.REACT_APP_API_URL + '/api/v1/blockchair/stats', {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            setIsLoading(false);
            setStats(response.data);
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.message);
            } else {
                setError('Something went wrong');
            }
            setIsLoading(false);
        }
    }

    return { stats, getStats, error, isLoading };
}