import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TransactionFormData } from '../../../interfaces/transaction';
import { useGetTransactionById } from '../../../hooks/useGetTransactionById';
import { useUpdateTransaction } from '../../../hooks/useUpdateTransaction';

import './UpdateTransaction.css';
import AdminNavbar from '../admin-navbar/AdminNavbar';

const UpdateTransaction: React.FC = () => {
    const { id } = useParams();
    const { transactionData, isLoading: isFetching, error: fetchError } = useGetTransactionById(id as string);
    const { updateTransaction, isLoading, error, isSuccess } = useUpdateTransaction();
    const [updatedData, setUpdatedData] = useState<TransactionFormData>({
        block_id: 0,
        hash: '',
        chain: '',
        input_total: 0,
        input_total_usd: 0,
        fee: 0,
        fee_usd: 0,
        fee_per_kb: 0,
        inputs: [{ value: 0, value_usd: 0, recipient: '' }],
        outputs: [{ value: 0, value_usd: 0, recipient: '' }],
        timeUntilFirstConfirmation: 0,
        timeBetweenConfirmations: 0,
    });

    useEffect(() => {
        if (transactionData && transactionData.inputs && transactionData.outputs) {
            setUpdatedData({
                ...updatedData,
                ...transactionData.transaction,
                timeUntilFirstConfirmation: transactionData.transaction.time_until_first_confirmation,
                timeBetweenConfirmations: transactionData.transaction.time_between_confirmations,
                inputs: transactionData.inputs,
                outputs: transactionData.outputs
            });
        } // eslint-disable-next-line
    }, [transactionData]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, type: 'inputs' | 'outputs') => {
        const newData = { ...updatedData };
        const value = event.target.type === 'number' ? Number(event.target.value) : event.target.value;

        if (type === 'inputs') {
            const updatedInputs = newData.inputs.map((input, i) => 
                i === index ? { ...input, [event.target.name]: value } : input
            );
            setUpdatedData({ ...newData, inputs: updatedInputs });
        } else {
            const updatedOutputs = newData.outputs.map((output, i) => 
                i === index ? { ...output, [event.target.name]: value } : output
            );
            setUpdatedData({ ...newData, outputs: updatedOutputs });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await updateTransaction(id as string, updatedData);
    };

    const handleGeneralChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setUpdatedData(prevData => ({
            ...prevData,
            [name]: name === 'block_id' ? parseInt(value, 10) : value
        }));
    };

    const removeInputOutput = (index: number, type: 'inputs' | 'outputs') => {
        setUpdatedData(prevData => ({
            ...prevData,
            [type]: prevData[type].filter((_, i) => i !== index)
        }));
    };

    const addInputOutput = (type: 'inputs' | 'outputs') => {
        setUpdatedData(prevData => ({
            ...prevData,
            [type]: [...prevData[type], { value: 0, value_usd: 0, recipient: '' }]
        }));
    };    

    

    if (isFetching) return <div>Loading...</div>;
    if (fetchError) return <div>Error: {fetchError}</div>;

    return (
        <>
        <AdminNavbar />
            <div className="update-transaction">
                <h2>Update Transaction</h2>
                <form onSubmit={handleSubmit}>
                    <div className="transaction-details">
                        <div className="field">
                            <label>Block ID:</label>
                            <input type="number" name="block_id" value={updatedData.block_id} onChange={handleGeneralChange} />
                        </div>

                        <div className="field">
                            <label>Hash:</label>
                            <input type="text" name="hash" value={updatedData.hash} onChange={handleGeneralChange} disabled/>
                        </div>

                        <div className="field">
                            <label>Cryptocurrency:</label>
                            <select name="chain" value={updatedData.chain} onChange={handleGeneralChange}>
                                <option value="">Select</option>
                                <option value="bitcoin">Bitcoin (BTC)</option>
                                <option value="ethereum">Ethereum (ETH)</option>
                            </select>
                        </div>
                    </div>

                    <div className="transaction-fields">
                        <div className="transaction-inputs">
                            <h3>Inputs</h3>
                            {updatedData.inputs.map((input, index) => (
                                <div key={index} className="input">
                                    <label>Value:</label>
                                    <input type="number" name="value" value={input.value} onChange={e => handleInputChange(index, e, 'inputs')} />
                                    <label>USD Value:</label>
                                    <input type="number" name="value_usd" value={input.value_usd} onChange={e => handleInputChange(index, e, 'inputs')} />
                                    
                                    <label>Recipient:</label>
                                    <input type="text" name="recipient" value={input.recipient} onChange={e => handleInputChange(index, e, 'inputs')} />
                                    <button type="button" className="red" onClick={() => removeInputOutput(index, 'inputs')}>Remove Input</button>
                                </div>
                            ))}
                            <button type="button" onClick={() => addInputOutput('inputs')}>Add Input</button>
                        </div>

                        <div className="transaction-outputs">
                            <h3>Outputs</h3>
                            {updatedData.outputs.map((output, index) => (
                                <div key={index} className="output">
                                    <label>Value:</label>
                                    <input type="number" name="value" value={output.value} onChange={e => handleInputChange(index, e, 'outputs')} />
                                    <label>USD Value:</label>
                                    <input type="number" name="value_usd" value={output.value_usd} onChange={e => handleInputChange(index, e, 'outputs')} />
                                    <label>Recipient:</label>
                                    <input type="text" name="recipient" value={output.recipient} onChange={e => handleInputChange(index, e, 'outputs')} />
                                    <button type="button" className="red" onClick={() => removeInputOutput(index, 'outputs')}>Remove Output</button>
                                </div>
                            ))}
                            <button type="button" onClick={() => addInputOutput('outputs')}>Add Output</button>
                        </div>
                    </div>

                    <div className="field">
                        <label>Time Until First Confirmation (minutes):</label>
                        <input type="number" name="timeUntilFirstConfirmation" value={updatedData.timeUntilFirstConfirmation} onChange={handleGeneralChange} />
                    </div>
                    <div className="field">
                        <label>Time Between Additional Confirmations (minutes):</label>
                        <input type="number" name="timeBetweenConfirmations" value={updatedData.timeBetweenConfirmations} onChange={handleGeneralChange} />
                    </div>

                    <button type="submit" disabled={isLoading}>Update Transaction</button>
                    {error && <p>{error}</p>}
                    {isSuccess && <p style={{color: '#fff', marginTop: '1rem'}}>Transaction updated successfully!</p>}
                </form>
            </div>
        </>
    );
};

export default UpdateTransaction;